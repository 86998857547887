import React from 'react';
import { Link } from "react-router-dom";


const StoreItemsSection = () => {
  return (
    <main className="main">
    <article> 
    <section className="store py-5">
      <div className="icons-bg"></div>
      
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-6" data-aos="fade-right">
            <div className="store-box">
              <div className="spacing"></div>
              
              <h1 className="my-4 heading-med">Add Gifts from Any online Store</h1>
              <p>
                Just share on the Quick Registries
                <Link className="change-color" to={process.env.REACT_APP_PLAY_STORE_URL} target="_blank"> app </Link>
                or click on our 'Add to Quick Registries' Chrome
                <Link className="change-color" to="https://chromewebstore.google.com/detail/quick-registry/bpfmfjcokemkfaofkheknefimcijonke?hl=en&authuser=8" target="_blank"> extension</Link>, 
                and add your favourite items on your baby registry!
              </p>              {/* <p>See the Favourites.</p>
              <p><Link to="#">Check out the Gifts Other Parents Love for Their Little Ones.</Link></p> */}

              <div className="button-container button my-5">
                <Link className="button-active" onClick={() => window.scrollTo(0, 0)}>Get Started</Link>
                <Link to="add-gifts" className="non-active-btn" onClick={() => window.scrollTo(0, 0)}>How to add gifts</Link>
              </div>
              <p className="my-3">Build your dream, baby registry now!</p>
            </div>
          </div>
          
          <div className="col-lg-1 display-none"></div>
          
          <div className="col-lg-5 col-md-6" data-aos="fade-left">
            <div className="store-img">
              <div className="spacing-mid"></div>
              
              <img src="assets/images/store-img.png" alt="" />
              
              <div className="spacing-mid"></div>
            </div>
          </div>
        </div>
      </div>
    </section>
    </article> 
    </main>
  );
};

export default StoreItemsSection;