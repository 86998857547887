import React from "react";
import { Link } from "react-router-dom";
import { getRegistryCredentials } from '../../utils/registryUtils';

const DownloadAppSection = () => {
  const { fromMobileApp } = getRegistryCredentials();

  if (fromMobileApp) {
    return null;
  }

  return (
    <main className="main">
      <article>
        <section className="download-app py-4">
          <div className="container">
            <div className="row">
              <div className="spacing-small"></div>

              <div className="col-md-6">
                <div
                  className="store-box"
                  data-aos="fade-right"
                  data-aos-delay="100"
                >
                  <h1 className="my-4 heading-med">
                    Download the Quick Registries App
                  </h1>
                </div>
              </div>

              <div className="col-md-6">
                <div
                  className="download-btn"
                  data-aos="fade-left"
                  data-aos-delay="100"
                >
                  <div className="spacing-mid"></div>

                  <Link to={process.env.REACT_APP_APP_STORE_URL} target="_blank">
                    <img src="/assets/images/app-store.png" alt="" />
                  </Link>

                  <Link to={process.env.REACT_APP_PLAY_STORE_URL} target="_blank">
                    <img src="/assets/images/google-play.png" alt="" />
                  </Link>
                </div>
              </div>

              <div className="spacing-small"></div>
            </div>
          </div>
        </section>
      </article>
    </main>
  );
};

export default DownloadAppSection;
