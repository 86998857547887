import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { showToast } from '../../utils/toastUtils';
import { GoogleAuth } from "@codetrix-studio/capacitor-google-auth";
import { initializeGoogleAuth } from "../../utils/googleAuthUtils";
import api from "../../services/api";
import Cookies from 'js-cookie';

const LoginModal = () => {
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [usernameError, setUsernameError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    initializeGoogleAuth();
  }, []);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSignup = () => {
    navigate("/signup");
  };

  const handleForgotPassword = () => {
    navigate("/forgot-password");
  };

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
    setUsernameError("");
    setError("");
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setPasswordError("");
    setError("");
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    let isUsernameValid = true;
    let isPasswordValid = true;

    if (!navigator.onLine) {
        showToast(<><i className="la la-warning i-icon"></i>&nbsp;You are offline!</>);
        return;
    }

    if (!username.trim()) {
        setUsernameError("Email is required. Please enter a valid email.");
        isUsernameValid = false;
    } else {
        setUsernameError("");
    }

    if (!password.trim()) {
        setPasswordError("Password is required. Please enter a valid password.");
        isPasswordValid = false;
    } else {
        setPasswordError("");
    }

    if (isUsernameValid && isPasswordValid) {
        try {
            setLoading(true);
            const response = await api.login(username, password);

            const userInfo = {
                accessToken: response.access_token,
                userId: response.user.id,
                userName: response.user.first_name,
                userEmail: response.user.email,
            };

            Object.entries(userInfo).forEach(([key, value]) => {
                Cookies.set(key, value, { expires: 30, sameSite: 'None', secure: true });
                localStorage.setItem(key, value);
            });

            if (response.registry_created === "true") {
              const registryList = response.user.registry;
              const primaryRegistry = registryList[0];
              let registryInfo;
      
              if (primaryRegistry.process_complete === "true") {
                registryInfo = {
                  registryId: primaryRegistry.id,
                  registryTypeId: primaryRegistry.registry_type_id,
                  registryTypeName: primaryRegistry.registry_type_name,
                  registryPublic: primaryRegistry.public,
                };
      
                if (registryList.length > 1) {
                  navigate("/all-registry");
                } else if (registryList.length === 1) {
                  navigate("/dashboard");
                }
              } else {
                registryInfo = {
                  stagingRegistryId: primaryRegistry.id,
                  stagingRegistryTypeId: primaryRegistry.registry_type_id,
                };
      
                navigate("/onboarding-process?processStep=2", { state: { initialRegistry: true } });
                showToast("Please complete your registry setup");
              }

              Object.entries(registryInfo).forEach(([key, value]) => {
                Cookies.set(key, value, { expires: 30, sameSite: 'None', secure: true });
                localStorage.setItem(key, value);
              });

            } else {
              navigate("/onboarding-process", { state: { initialRegistry: true } });
              showToast("Please complete your registry setup");
            }

            document.getElementById("Login").click();
        } catch (error) {
            if (error.message === "Invalid credentials") {
                setError("Your email or password is incorrect");
            } else {
                setError("An error occurred. Please try again.");
            }
        } finally {
            setLoading(false);
        }
    }
  };

  const googleLogin = async () => {
    try {
        const response = await GoogleAuth.signIn({
            prompt: "select_account",
        });
        handleGoogleLogin(response);
    } catch (error) {
        console.error("Error during Google login:", error);
    }
  };

  const handleGoogleLogin = async (response) => {
    try {
        const id_token = response.authentication.accessToken;
        const apiResponse = await api.googleLogin(id_token);
        const { access_token, user } = apiResponse.user.original;
        const userType = apiResponse.user.original.userType;
        const registryCreated = apiResponse.user.original.registry_created === 'true';

        Object.entries({
          accessToken: access_token,
          userId: user.id,
          userName: user.first_name,
          userEmail: user.email,
        }).forEach(([key, value]) => {
          Cookies.set(key, value, { expires: 30, sameSite: 'None', secure: true });
          localStorage.setItem(key, value);
        });

        if (userType === 'new') {
          navigate('/onboarding-process', { state: { initialRegistry: true } });
        } else if (userType === 'old') {
            if (registryCreated) {
              const registryList = user.registry;
              const primaryRegistry = registryList[0];
              const processComplete = primaryRegistry.process_complete === 'true';
              let registryInfo;
      
              if (processComplete) {
                registryInfo = {
                  registryId: primaryRegistry.id,
                  registryTypeId: primaryRegistry.registry_type_id,
                  registryTypeName: primaryRegistry.registry_type_name,
                  registryPublic: primaryRegistry.public,
                };
      
                if (registryList.length > 1) {
                  navigate("/all-registry");
                } else if (registryList.length === 1) {
                  navigate("/dashboard");
                }
              } else {
                registryInfo = {
                  stagingRegistryId: primaryRegistry.id,
                  stagingRegistryTypeId: primaryRegistry.registry_type_id,
                };
      
                navigate("/onboarding-process?processStep=2", { state: { initialRegistry: true } });
                showToast("Please complete your registry setup");
              }

              Object.entries(registryInfo).forEach(([key, value]) => {
                Cookies.set(key, value, { expires: 30, sameSite: 'None', secure: true });
                localStorage.setItem(key, value);
              });
            } else {
              navigate("/onboarding-process", { state: { initialRegistry: true } });
              showToast("Please Select a registry");
            }
          }
        
        document.getElementById("Login").click();
    } catch (error) {
        console.error('Error during Google login:', error);
        showToast("Failed to Google login");
    }
  };

  const handleCancel = () => {
    setUsername("");
    setPassword("");
    setError("");
  };
  
  return (
    <div className={`modal fade`} id="Login" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className={`modal-dialog`}>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Login to Quick Registries
            </h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleCancel}></button>
          </div>
          <div className="modal-body login-box">
            <form noValidate>
              {error && (
                  <p className="validation-login-main text-danger text-center">{error}</p>
              )}
              <div className="user-box">
                <input
                  type="text"
                  name="username"
                  value={username}
                  onChange={handleUsernameChange}
                  onKeyPress={(e) => e.key === 'Enter' && handleLogin(e)}
                  className={`form-control ${usernameError ? "is-invalid" : ""}`}
                  required
                />
                <label>Email</label>
                <p className="validation invalid-feedback">{usernameError}</p>
              </div>
              <div className="user-box">
                <input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  value={password}
                  onChange={handlePasswordChange}
                  onKeyPress={(e) => e.key === 'Enter' && handleLogin(e)}
                  className={`form-control ${passwordError ? "is-invalid" : ""}`}
                  maxLength={20}
                  required
                />
                <label>Password</label>
                <Link
                  className="password-toggle-icon login-picon end-0 me-3"
                  onClick={togglePasswordVisibility}
                >
                  {showPassword ? (
                    <i className="las la-eye-slash fs-4"></i>
                  ) : (
                    <i className="las la-eye fs-4"></i>
                  )}
                </Link>
                <p className="validation invalid-feedback">{passwordError}</p>
              </div>

              <div className="user-box float-end">
                <Link data-bs-dismiss="modal" onClick={handleForgotPassword}>
                  Forgot Password?
                </Link>
              </div>
              <button type="submit" className="visually-hidden">Submit</button>
            </form>
          </div>
          <div className="modal-footer">
          <button type="button" className="btn btn-primary form-btn main-btn" onClick={handleLogin} disabled={loading}>
              {loading ? (
                <>
                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> &nbsp;Logging in...
                </>
              ) : (
                "Login"
              )}
            </button>
            <div className="user-box or">
              <p>or</p>
            </div>
            <div className="user-box">
              <div className="social-btn">
                <Link to="#" className="google-btn" onClick={() => googleLogin()}>
                  <img src="/assets/images/icon/google-icon.svg" alt="Google icon" className="me-2" style={{ width: '22px', height: '22px' }} />
                  Sign in with Google
                </Link>
              </div>
            </div>
            <div className="user-box pt-4 pb-2">
              <p>
                Don’t have an account? <Link onClick={handleSignup} data-bs-dismiss="modal">Sign Up</Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginModal;