import React from "react";

const BuyMultipleGiftsModal = ({ productData }) => {
  return (
    <div className="modal fade" id="BuyMultipleGifts" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog common-popup buy-mulitple-popup">
        <div className="modal-content">
          <div className="modal-header">
            <p>BUY MULTIPLE GIFTS</p>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body row">
            <h5 className="multiple-gift-heading mt-3 mb-4 text-center">
              To buy more than one registry gift from {productData?.platform_name}:
            </h5>
            <div>
              <div className="multiple-gift-info-container">
                <span className="mulitple-list-number">1</span> 
                <span className="multiple-gift-info">Once you’re redirected to the store, place the first item in the cart.</span>
              </div>
              <div className="multiple-gift-info-container">
                <span className="mulitple-list-number">2</span>
                <span className="multiple-gift-info">Return to Quick Registries, SKIP the purchase question, and click "Select more registry items."</span> 
              </div>
              <div className="multiple-gift-info-container">
                <span className="mulitple-list-number">3</span>
                <span className="multiple-gift-info">Add the next item  to the cart and repeat step 2 until you're ready to check out.</span> 
              </div>
              <div className="multiple-gift-info-container mb-4">
                <span className="mulitple-list-number">4</span>
                <span className="multiple-gift-info">Complete checkout, then return to Quick Registries once more to update the registry.</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BuyMultipleGiftsModal;
