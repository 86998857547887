import React from "react";
// import { Link } from "react-router-dom";
import Header from "../../components/common/Header";
import Navbar from "../../components/common/Navbar";
import Footer from "../../components/common/Footer";

const NotificationsPage = () => {
  return (
    <div className="wrapper animsition">
      <div className="wrapper-inner">
        <header className="header">
          <Header />
          <Navbar />
        </header>
          <main className="main main-dashboard">
            <article>
              <section>
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="dashboard">
                        <div className="user-dashboard-page">
                          <div className="guestbook-heading">
                            <h2>Notifications</h2>
                          </div>
                        </div>

                        {/* <div className="notification-container">
                         <p className="notification-date">JANUARY 1ST, 2024</p>
                          <div className="notification-info">
                            <hr className="date-line" />
                            <p className="notification-title">THE GIFT(LIST) THAT KEEPS ON GIVING</p>
                            <p>
                                Welcome to Quick Registries, and congratulations on your baby’s upcoming arrival! We’ll be here for you for 
                                the happy occasion, and want to continue to meet your registry needs for all of life’s milestones. Follow us
                                on Instagram, Facebook, Twitter and Pinterest for tips, advice, inspo & a chance to win freebies!
                            </p>
                            <p>
                                Ready to add gifts? <Link to="/add-gifts" className="change-color" >Click here</Link> to learn how.
                            </p>
                            <hr />
                          </div>
                        </div> */}

                        <div className="no-notification">
                            <p>No notifications available. Check back later for any updates.</p>
                        </div>

                        <div className="modal fade" id="delete-modal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                          <div className="modal-dialog">
                            <div className="modal-content">
                              <div className="modal-body row">
                                <div className="col-lg-12">
                                  <div className="confirmation-modal text-center mt-4">
                                    <h5 className="mb-4">Delete Confirmation</h5>
                                    <p> Are you sure you want to delete this notification?</p>
                                  </div>
                                  <div className="user-detials-btn text-center mt-4 mb-4">
                                    <button type="button" className="common-modal-btn common-btn">NO</button>
                                    <button type="button" className="common-modal-btn common-btn active-btn">YES</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </article>
          </main>
        <Footer />
      </div>
    </div>
  );
};

export default NotificationsPage;
