import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getRegistryCredentials } from "../../utils/registryUtils";
import { showToast } from "../../utils/toastUtils";
import Header from "../../components/common/Header";
import Navbar from "../../components/common/Navbar";
import Footer from "../../components/common/Footer";
import dayjs from 'dayjs';
import api from "../../services/api";
import Dialog from "@mui/material/Dialog";
import { useMediaQuery } from '@mui/material';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";

const PurchaseProcessPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const initialStep = parseInt(searchParams.get("stepNav")) || 1;
  const itemId = searchParams.get("itemId");
  const regId = searchParams.get("regId");
  const [step, setStep] = useState(initialStep);
  const [loading, setLoading] = useState(true);
  const [publicKey, setPublicKey] = useState(null);
  const [reason, setReason] = useState("looking");
  const [productData, setProductData] = useState(null);
  const [shippingData, setShippingData] = useState(null);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [userDetails, setUserDetails] = useState(null);
  const [isGiftBought, setIsGiftBought] = useState(true);
  const [isSamePlatform, setIsSamePlatform] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [isQtyValid, setIsQtyValid] = useState(true);
  const [keepSurprise, setKeepSurprise] = useState(false);
  const [purchaserFirstName, setPurchaserFirstName] = useState("");
  const [purchaserLastName, setPurchaserLastName] = useState("");
  const [purchaserEmail, setPurchaserEmail] = useState("");
  const [storeName, setStoreName] = useState("");
  const [orderID, setOrderID] = useState("");
  const [openCalender, setOpenCalendar] = useState(false);
  const [surpriseDate, setSurpriseDate] = useState(dayjs(new Date()));
  const [tempSurpriseDate, setTempSurpriseDate] = useState(null);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [showShippingAddress, setShowShippingAddress] = useState(false);
  const [alternateTitle, setAlternateTitle] = useState("Do you buy it?");
  const { accessToken } = getRegistryCredentials();
  const isPortrait = useMediaQuery('(max-width:767px)');

  useEffect(() => {
    const interval = setInterval(() => {
      setAlternateTitle((prevTitle) =>
        prevTitle === "Do you buy it?" ? "Quick Registries" : "Do you buy it?"
      );
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    document.title = alternateTitle;
  }, [alternateTitle]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = "";
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const handleStepChange = (newStep) => {
    setStep(newStep);
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const itemId = searchParams.get("itemId");
    const regId = searchParams.get("regId");
  
    if (itemId && regId) {
      sessionStorage.setItem('registryParams', JSON.stringify({
        itemId,
        regId
      }));
    }
  
    if (!itemId || !regId) {
      const storedParams = JSON.parse(sessionStorage.getItem('registryParams'));
      if (storedParams && storedParams.itemId && storedParams.regId) {
        const { itemId: storedItemId, regId: storedRegId } = storedParams;
        navigate(`?itemId=${storedItemId}&regId=${storedRegId}`);
      }
    }
  
  }, [location.search, navigate]);

  useEffect(() => {
    const fetchProductData = async () => {
      try {
        if (itemId && regId) {
          const response = await api.getVisitorItem(itemId, regId);
  
          if (response.password_protected) {
            setLoading(true);
            setUserDetails({
              firstName: response.user_first_name,
              lastName: response.user_last_name,
              userAnswers: response.user_answer,
            });
            setPublicKey(response.public_key);
  
            const storedPassword = localStorage.getItem(response.public_key);
  
            if (storedPassword) {
              const decodedPassword = atob(storedPassword);
  
              try {
                const passwordResponse = await api.getVisitorItem(itemId, regId, decodedPassword);
  
                setProductData(passwordResponse.data);
                setUserDetails({
                  firstName: passwordResponse.data.user_first_name,
                  lastName: passwordResponse.data.user_last_name,
                  userAnswers: passwordResponse.data.user_answer,
                });
                setPublicKey(passwordResponse.data.public_key);
              } catch (error) {
                console.error("Error fetching product data with password:", error);
                navigate(`/view-product?itemId=${itemId}&regId=${regId}`);
              }
            } else {
              console.log("No password found");
              navigate(`/view-product?itemId=${itemId}&regId=${regId}`);
            }
            return;
          }
  
          setProductData(response.data);
          setUserDetails({
            firstName: response.data.user_first_name,
            lastName: response.data.user_last_name,
            userAnswers: response.data.user_answer,
          });
          setPublicKey(response.data.public_key);
        }
      } catch (error) {
        console.error("Error fetching product data:", error);
        navigate(`/view-product?itemId=${itemId}&regId=${regId}`);
      } finally {
        setLoading(false);
      }
    };
  
    fetchProductData();
    // eslint-disable-next-line
  }, [itemId, regId]);
   

  const fetchShippingData = async () => {
    setLoading(true); 
    const { jsonWebToken } = getRegistryCredentials();
    try {
      if (accessToken) {
        const data = await api.getAddressToVisitor(accessToken, publicKey);
        setShippingData(data.shipping_info);
      } else {
        if (jsonWebToken) {
          const data = await api.showAddressToVisitor(jsonWebToken, publicKey);
          setShippingData(data.shipping_info);
        } else {
          console.error("Visitor is not verified.");
        }
      }
    } catch (error) {
      console.error("Error fetching shipping data:", error.message);
    } finally {
      setLoading(false);
    }   
  };

  useEffect(() => {
    if (publicKey) {
      fetchShippingData();
    }
    // eslint-disable-next-line
  }, [accessToken, publicKey]);

  const handleShowShipping = () => {
    setShowShippingAddress(!showShippingAddress);
  }

  const validateQuantity = (quantity) => {
    const parsedQuantity = parseInt(quantity);
    if (!parsedQuantity || parsedQuantity <= 0 || parsedQuantity > 999) {
      showToast('Please enter a valid quantity');
      setIsQtyValid(false);
      return false;
    }
    return true;
  }

  const handleYes = () => {
    setIsGiftBought(true);
    handleStepChange(2);
    window.scrollTo(0, 0);
  };

  const handleNo = () => {
    setIsGiftBought(false);
    handleStepChange(2);
    window.scrollTo(0, 0);
  };

  const handleDone = async () => {
    if (reason === "not-available") {
      try {
        await api.markItemUnavailable(itemId, regId);
        showToast('Thank you, We will notify the registry holder');
      } catch (error) {
        console.error('Error marking item as unavailable:', error);
        showToast('Failed to mark item as unavailable');
      }
    }
    navigate(`/giftlist/${productData?.public_key}`);
  };

  const handleSamePlatform = () => {
    if (!validateQuantity(quantity)) return;
    setIsSamePlatform(true);
    handleStepChange(3);
    window.scrollTo(0, 0);
  }
  
  const handleAnotherStore = () => {
    if (!validateQuantity(quantity)) return;
    setIsSamePlatform(false);
    handleStepChange(3);
    window.scrollTo(0, 0);
  }

  const handleKeepSurprise = (e) => {
    setKeepSurprise(e.target.checked);
  };

  const handleSurpriseDate = () => {
      setOpenCalendar(true);
  };

  const handleSaveSurpriseDate = (date) => {
    setTempSurpriseDate(date);
    setOpenCalendar(false);
  };

  const handleCloseSurpriseDate = () => {
    setOpenCalendar(false);
  };

  const handleStoreNameChange = (e) => {
    setStoreName(e.target.value);
  };

  const handleOrderIDChange = (e) => {
    setOrderID(e.target.value);
  };

  const handleFirstNameChange = (e) => {
    setPurchaserFirstName(e.target.value);
  };

  const handleLastNameChange = (e) => {
    setPurchaserLastName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setPurchaserEmail(e.target.value);
  };

  const validatePurchaserDetails = () => {
    const isFirstNameValid = !!purchaserFirstName.trim();
    const isLastNameValid = !!purchaserLastName.trim();
    const isEmailValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(purchaserEmail);
    const isSurpriseDateValid = keepSurprise ? !!tempSurpriseDate : true;
    const isStoreNameValid = isSamePlatform || !!storeName.trim();
  
    return {
      isFirstNameValid,
      isLastNameValid,
      isEmailValid,
      isSurpriseDateValid,
      isStoreNameValid,
    };
  };
  
  const handleSubmit = async () => {
    try {
        setSubmitLoading(true);
        const purchaseData = {
          name: `${purchaserFirstName} ${purchaserLastName}`,
          email: purchaserEmail,
          item_id: itemId,
          qty: quantity,
          store_name: storeName || null,
          order_number: orderID || null,
          secret: keepSurprise,
          secret_open: keepSurprise ? tempSurpriseDate.format('YYYY-MM-DD') : null
        };
  
        await api.purchaserDetails(purchaseData);
        showToast('Thank you for your purchase!');
        navigate(`/giftlist/${productData?.public_key}`);
    } catch (error) {
      console.error('Error submitting purchase details:', error);
      showToast('Failed to submit purchase details');
    } finally {
      setSubmitLoading(false);
    }
  };
  
  useEffect(() => {
    const { isFirstNameValid, isLastNameValid, isEmailValid, isSurpriseDateValid, isStoreNameValid } = validatePurchaserDetails();
    setIsSubmitDisabled(
      !isFirstNameValid || !isLastNameValid || !isEmailValid || !isSurpriseDateValid || !isStoreNameValid
    );
    // eslint-disable-next-line
  }, [purchaserFirstName, purchaserLastName, purchaserEmail, keepSurprise, tempSurpriseDate, isSamePlatform, storeName]);   
  
  const handleBack = () => {
    handleStepChange(step - 1);
    window.scrollTo(0, 0);
  };

  return (
    <div className="wrapper animsition">
    {loading ? (
        <div className="custom-loader-container fs-5">
          <div className="spinner-border custom-loader" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
      <div className="wrapper-inner">
        <header className="header">
          <Header />
          <Navbar />
        </header>
        <main className="main">
          <article>
            <section className="py-5">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="dashboard">
                      <div className="purchaser-dashboard">
                        <div className="view-visitor-product">
                          <div className="product-popup">
                            <div className="modal-body login-box row">
                              <div className="col-lg-6 col-md-6">
                                <div className="product-image">
                                  {productData?.image ? (
                                    <img
                                      src={productData?.image}
                                      alt=""
                                      className="d-block mx-auto"
                                      style={{
                                        height: "325px",
                                        objectFit: "contain",
                                      }}
                                    />
                                  ) : (
                                    <img
                                      src="https://www.mobismea.com/upload/iblock/2a0/2f5hleoupzrnz9o3b8elnbv82hxfh4ld/No%20Product%20Image%20Available.png"
                                      alt=""
                                      className="img-fluid d-block mx-auto"
                                      style={{
                                        height: "325px",
                                        objectFit: "contain",
                                      }}
                                    />
                                  )}
                                </div>
                              </div>
                              <div className="col-lg-6 col-md-6">
                                <div className="product-form">
                                {step === 1 && (
                                  <>
                                  <p className="purchase-heading mb-3">Did you buy this gift?</p>

                                  <p className="purchase-info mb-4">
                                    Please update the registry for&nbsp;
                                    <span className="purchase-info change-name-color">
                                      {userDetails?.firstName}
                                      {userDetails.userAnswers && ` & ${userDetails.userAnswers[1].question_answer.split(' ')[0]}`}
                                    </span>
                                  </p>
                                  
                                  <div className="user-detials-btn mb-5">
                                    <button type="button" className="purchase-btn common-btn active-btn" onClick={handleYes}>YES</button>
                                    <button type="button" className="purchase-btn common-btn" onClick={handleNo}>NO</button>
                                 </div>

                                  <div className="button-already primary-button all">
                                    <p className="footer-info">Return to <Link to={productData?.item_url} className="change-color" target="_blank" rel="noopener noreferrer"> {productData?.platform_name ? productData.platform_name.toUpperCase() : 'PLATFORM'}</Link> to purchase this gift</p>
                                    {shippingData && (
                                      <>
                                        <div className={`collapse ${showShippingAddress ? 'show' : ''}`} id="shippingAddressCollapse">
                                          <div className="user-address-shipping-info">
                                            <p>Their shipping address is:</p>
                                            <p className="user-address-detail">
                                              {shippingData.first_name}&nbsp;
                                              {shippingData.last_name}
                                              <br />
                                              {shippingData.address_1}
                                              {shippingData.address_2 && `, ${shippingData.address_2}`}
                                              <br />
                                              {shippingData.city},&nbsp;
                                              {shippingData.state},&nbsp;
                                              {shippingData.zip_code}
                                              <br />
                                              {shippingData.country}
                                              <br />
                                              {shippingData.instructions && (
                                                <>
                                                  <p className="user-address-detail">Instructions:</p>
                                                  <div>
                                                    <p className="user-instruction-detail">{shippingData.instructions}</p>
                                                  </div>
                                                </>
                                              )}
                                            </p>
                                          </div>
                                        </div>
                                        {!showShippingAddress && (
                                          <p className="footer-info">
                                          Need their shipping address? 
                                          <span onClick={handleShowShipping} data-bs-toggle="collapse" href="#shippingAddressCollapse">
                                          <Link className="change-color"> CLICK HERE</Link>
                                          </span>
                                        </p>
                                        )}
                                      </>
                                    )}
                                    <hr></hr>
                                    <p className="footer-info">Select <Link to={`/giftlist/${productData?.public_key}`} className="change-color"> MORE</Link> registry items</p>
                                  </div>
                                  </>
                                  )}
                                  
                                {step === 2 && (
                                  isGiftBought ? ( 
                                    <>
                                      <p className="purchase-heading mb-4">
                                        I bought this at:
                                      </p>
                                      <div className="user-box quantity-label">
                                        <p className="purchase-info">Qty: </p>
                                      </div>
                                      <div className="user-box quantity-input">
                                        <input 
                                          type="number"
                                          name="Qty"
                                          value={quantity}
                                          onChange={(e) => {
                                          const inputVal = e.target.value;
                                          if (/^\d{0,3}$/.test(inputVal)) {
                                            setQuantity(inputVal);
                                            setIsQtyValid(true);
                                          }
                                        }}
                                          className={`form-control ${!isQtyValid ? "is-invalid" : ""}`}
                                          required
                                        />
                                      </div>
                                      <div className="user-detials-btn mt-2 mb-3">
                                        <button type="button" className="store-btn common-btn active-btn" onClick={handleSamePlatform}>{productData?.platform_name ? productData.platform_name.toUpperCase() : 'SAME PLATFORM'}</button>
                                      </div>
                                      <div className="user-detials-btn mb-5">
                                        <button type="button" className="store-btn common-btn" onClick={handleAnotherStore}>ANOTHER STORE</button>
                                      </div>
                                      <Link to="#" className="change-color" onClick={handleBack}><i className="la la-angle-left"></i> GO BACK A STEP</Link>
                                    </>
                                  ) : (
                                    <>
                                      <p className="purchase-heading mb-3">I didn't buy this gift because:</p>
                                      <div className="form-check mt-4">
                                        <input 
                                          className="form-check-input" 
                                          type="radio" 
                                          name="reason" 
                                          id="just-looking" 
                                          value="looking" 
                                          checked={reason === "looking"}
                                          onChange={() => setReason("looking")} 
                                        />
                                        <label className="purchase-option form-check-label" htmlFor="just-looking">
                                          I was just looking
                                        </label>
                                      </div>
                                      <div className="form-check mt-4">
                                        <input 
                                          className="form-check-input" 
                                          type="radio" 
                                          name="reason" 
                                          id="not-available" 
                                          value="not-available"
                                          checked={reason === "not-available"}
                                          onChange={() => setReason("not-available")} 
                                        />
                                        <label className="purchase-option form-check-label" htmlFor="not-available">
                                          The item wasn't available
                                        </label>
                                      </div>
                                      <div className="user-detials-btn mt-5 mb-5">
                                        <button type="button" className="purchase-done-btn common-btn active-btn" onClick={handleDone}>DONE</button>
                                      </div>
                                      <Link to="#" className="change-color" onClick={handleBack}><i className="la la-angle-left"></i> GO BACK A STEP</Link>
                                    </>
                                  )
                                )}

                                {step === 3 && (
                                <>
                                {isSamePlatform ? (
                                  <p className="purchase-heading mb-4">
                                    And finally, please enter your contact info so&nbsp;
                                    <span className="purchase-heading change-name-color mb-4">
                                      {userDetails?.firstName}
                                      {userDetails?.userAnswers && userDetails.userAnswers[1]?.question_answer && (
                                        <> &amp; {userDetails.userAnswers[1].question_answer.split(" ")[0]}'s </>
                                      )}
                                    </span>
                                    registry can be updated.
                                  </p>
                                ) : (
                                  <p className="purchase-heading mb-4">Please provide your purchase details</p>
                                )}
                                
                                <div className="surprise-checkbox-container">
                                  <div className="user-box-checkbox mb-4">
                                    <input
                                      type="checkbox"
                                      name="keepSurprise"
                                      className="mt-2 mark-as-purchased"
                                      checked={keepSurprise}
                                      onChange={handleKeepSurprise}
                                    />&nbsp;&nbsp;
                                    <label className="purchased-text fs-6">
                                       Keep my details a surprise {keepSurprise ? 'until' : ' '} 
                                      </label>
                                      {keepSurprise && (
                                      <input
                                        type="text"
                                        value={tempSurpriseDate ? tempSurpriseDate.format('DD/MM/YYYY') : ''}
                                        placeholder="dd/mm/yyyy"
                                        onClick={handleSurpriseDate}
                                        className="surprise-date-input"
                                      />
                                    )}
                                    
                                  </div>
                                </div>
                                <Dialog open={openCalender}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                  <StaticDatePicker
                                    orientation={isPortrait ? 'portrait' : 'landscape'}
                                    value={surpriseDate}
                                    onChange={setSurpriseDate}
                                    renderInput={(params) => <input {...params} />}
                                    minDate={dayjs().add(1, 'day')}
                                    onAccept={handleSaveSurpriseDate}
                                    onClose={handleCloseSurpriseDate}
                                  />
                                </LocalizationProvider>
                              </Dialog>

                                  {!isSamePlatform && (
                                    <div className="user-box product-field">
                                      <div className="product-column another-platform">
                                        <input
                                          type="text"
                                          name="storeName"
                                          required
                                          value={storeName}
                                          onChange={handleStoreNameChange}
                                        />
                                        <label>Store Name</label>
                                      </div>
                                      <div className="product-column another-platform">
                                        <input
                                          type="text"
                                          name="orderID"
                                          required
                                          value={orderID}
                                          onChange={handleOrderIDChange}
                                        />
                                        <label>{isPortrait ? 'Order ID' : 'Order ID (If Known)'}</label>
                                      </div>
                                    </div>
                                  )}

                                  <div className="user-box product-field">
                                    <div className="product-column">
                                      <input
                                        type="text"
                                        name="firstName"
                                        required
                                        value={purchaserFirstName}
                                        onChange={handleFirstNameChange}
                                      />
                                      <label>First Name</label>
                                    </div>

                                    <div className="product-column">
                                      <input
                                        type="text"
                                        name="lastName"
                                        required
                                        value={purchaserLastName}
                                        onChange={handleLastNameChange}
                                      />
                                      <label>Last Name</label>
                                    </div>
                                  </div>

                                  <div className="user-box">
                                    <input
                                      type="email"
                                      name="email"
                                      required
                                      value={purchaserEmail}
                                      onChange={handleEmailChange}
                                    />
                                    <label>Email address</label>

                                    <div className="user-detials-btn mb-3">
                                      <button type="button" className="purchase-done-btn btn common-btn active-btn" onClick={handleSubmit} disabled={isSubmitDisabled || submitLoading}>SUBMIT</button>
                                    </div>

                                    <p className="mb-4">We'll never share your info with any third parties.<Link to="/privacy-policy" target="_blank" rel="noopener noreferrer" className="change-color"> PRIVACY POLICY</Link></p>
                                    <Link to="#" className="change-color" onClick={handleBack}><i className="la la-angle-left"></i> GO BACK A STEP</Link>
                                  </div>
                                </>
                              )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </article>
        </main>
        <Footer />
      </div>
    )}
    </div>
  );
};

export default PurchaseProcessPage;
