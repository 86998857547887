import React from "react";
import Header from "../../components/common/Header";
import Footer from "../../components/common/Footer";
import Navbar from "../../components/common/Navbar";
import { Link } from "react-router-dom";

const TermsAndConditionPage = () => {
  const startYear = 2024;
  const currentYear = new Date().getFullYear();
  const displayYear = startYear === currentYear ? `${startYear}` : `${startYear}–${currentYear}`;

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
    });
  };

  return (
    <div className="wrapper animsition">
      <div className="wrapper-inner">
        <header className="header">
          <Header />
          <Navbar />
        </header>
        <main className="main">
          <article>
            <section className="py-5">
              <div className="container privacy text-justify">
                <div className="mb-5 text-center">
                  <p className="privacy-heading">Quick Registries Terms & Conditions</p>
                </div>
                <div>
                  <p>
                    Quick Registries (“<u>Quick Registries,</u>” “<u>we,</u>” “
                    <u>us,</u>” or “<u>our</u>”) welcomes you. We invite you to
                    access and use our online services (the “<u>Services</u>
                    ”) which are made available to you through a variety of
                    platforms, including, but not limited to,&nbsp;
                    <Link to="/" className="custom-link" onClick={scrollToTop}>
                      www.quickregistries.com
                    </Link>
                    &nbsp;(the “<u>Website</u>
                    ”) and our mobile app, which is accessible through tablets,
                    smartphones, connected televisions, and other devices (the “
                    <u>App</u>”). The Website and the App are collectively
                    referred to as the “<u>Platform</u>.” We provide Visitors
                    (as defined below) with access to the Website and Members
                    and Guests (all as defined below) with access to the
                    Services subject to the following Terms of Use, which may be
                    updated by us from time to time without notice to you. By
                    browsing the public areas of the Website or by accessing and
                    using the Platform, you acknowledge that you have read,
                    understood, and agree to be legally bound by these Terms of
                    Use and our Privacy Policy, which is hereby incorporated by
                    reference (collectively, this “<u>Agreement</u>”). If you do
                    not agree to any of these terms, then please do not use the
                    Website and/or the Platform. By signing up, accessing,
                    purchasing something from us, and/or using the Platform,
                    each Merchant (as defined below) is agreeing to the terms of
                    our Privacy Policy and the accompanying Merchant Agreement.
                    These Terms and Conditions apply to all users of the site,
                    including without limitation users who are browsers,
                    vendors, customers, merchants, and/or contributors of
                    content.
                  </p>
                  <br />
                  <p>
                    Any new features or tools that are added to the website
                    and/or current store shall also be subject to these Terms
                    and Conditions. You can review the most current version of
                    the Terms and Conditions at any time on this page. We
                    reserve the right to update, change, or replace any part of
                    these Terms and Conditions by posting updates and/or changes
                    to our website. It is your responsibility to check this page
                    periodically for changes. Your continued use of or access to
                    the website following the posting of any changes constitutes
                    acceptance of those changes.
                  </p>
                </div>
                <div className="section-highlight mt-4">
                  <p>
                    THE SECTIONS BELOW TITLED “BINDING ARBITRATION” AND “CLASS
                    ACTION WAIVER” CONTAIN A BINDING ARBITRATION AGREEMENT AND
                    CLASS ACTION WAIVER. THEY AFFECT YOUR LEGAL RIGHTS. PLEASE
                    READ THEM.
                  </p>
                </div>
                <div className="number-spacing mt-4">
                  <p className="mb-5">
                    Capitalized terms not defined in these Terms of Use shall
                    have the meaning set forth in our Privacy Policy.
                  </p>
                  <ol>
                    <li>
                      <p>
                        <strong className="section-heading">
                          <u>
                            DESCRIPTION AND USE OF OUR PLATFORM AND SERVICES
                          </u>
                        </strong>
                        <div className="section-space"></div>
                        We provide Visitors with access to the Website and
                        Members and Guests with access to the Services as
                        described below.
                        <div className="paragraph-space"></div>
                        <ol>
                          <p>
                            <u>Visitors</u>. Visitors, as the term implies, are
                            people who do not register with us, but want to
                            explore the Website. No login is required for
                            Visitors. Visitors can: (i) view all
                            publicly-available content on the Website; and (ii)
                            e-mail us.
                          </p>
                          <p>
                            <u>Members</u>. Login is required for all Members.
                            Members can do all the things that Visitors can do,
                            and: (i) create, access, manage, and update their
                            own personal accounts on the Platform; (ii) create,
                            access, manage, and update their own registry
                            through the Services; (iii) access and use our
                            Services to purchase gifts from other Members
                            registry; or (iv) post comments and other content
                            through the Services (collectively, “Member
                            Content”).
                          </p>
                          <p>
                            <u>Guests</u>. Guests, as the term implies, are
                            people who do not register with, but want to use our
                            Services to access, view and/or purchase gifts from
                            a Member’s registry. No login is required for
                            Guests, but a password may be required to view a
                            Member’s registry. Guests can do all the things that
                            Visitors can do.
                          </p>
                          <p>
                            <u>Merchants</u>. Login is required for all
                            Merchants. Merchants can do all the things that
                            Visitors can do, and fulfill orders for gifts
                            purchased from a Member’s registry. As noted above,
                            Merchants’ access to and usage of the Platform is
                            governed by the Merchant Agreement.
                          </p>
                        </ol>
                        <div className="paragraph-space"></div>
                        Quick Registries is under no obligation to accept any
                        individual as a Member, Guest or Merchant and may accept
                        or reject any registration in its sole and complete
                        discretion. In addition, Quick Registries may deactivate
                        any account at any time, including, without limitation,
                        if it determines that a Member, Guest or Merchant has
                        violated these Terms of Use.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong className="section-heading">
                          <u>GENERAL CONDITIONS</u>
                        </strong>
                        <div className="section-space"></div>
                        We reserve the right to refuse service to anyone for any
                        reason at any time. You understand that your content
                        (not including credit card information) may be
                        transferred unencrypted and involve (a) transmissions
                        over various networks; and (b) changes to conform and
                        adapt to technical requirements of connecting networks
                        or devices. Credit card information is always encrypted
                        during transfer over networks. You agree not to
                        reproduce, duplicate, copy, sell, resell, or exploit any
                        portion of the Service, use of the Service, or access to
                        the Service, or any contact on the website through which
                        the Service is provided, without express written
                        permission by us. The headings used in this agreement
                        are included for convenience only and will not limit or
                        otherwise affect these Terms.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong className="section-heading">
                          <u>ONLINE STORE TERMS</u>
                        </strong>
                        <div className="section-space"></div>
                        By agreeing to these Terms and Conditions, you represent
                        that you are at least the age of majority in your state
                        or province of residence, or that you are the age of
                        majority in your state or province of residence and you
                        have given us your consent to allow any of your minor
                        dependents to use this site. You may not use our
                        products for any illegal or unauthorized purpose nor may
                        you, in the use of the Service, violate any laws in your
                        jurisdiction (including but not limited to copyright
                        laws). You must not transmit any worms or viruses or any
                        code of a destructive nature. A breach or violation of
                        any of the Terms will result in an immediate termination
                        of your Services.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong className="section-heading">
                          <u>USE OF PERSONAL INFORMATION</u>
                        </strong>
                        <div className="section-space"></div>
                        Your use of the Platform may involve the transmission to
                        us of certain Personal Information. Our policies with
                        respect to the collection and use of such Personal
                        Information are governed according to our Privacy Policy
                        (located at{" "}
                        <Link
                          to="/privacy-policy"
                          className="custom-link"
                          onClick={scrollToTop}
                        >
                          {`${process.env.REACT_APP_BASE_URL}/privacy-policy`}
                        </Link>
                        ), which is hereby incorporated by reference in its
                        entirety.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong className="section-heading">
                          <u>
                            ACCURACY, COMPLETENESS AND TIMELINESS OF INFORMATION
                          </u>
                        </strong>
                        <div className="section-space"></div>
                        We are not responsible if information made available on
                        this site is not accurate, complete, or current. The
                        material on this site is provided for general
                        information only and should not be relied upon or used
                        as the sole basis for making decisions without
                        consulting primary, more accurate, more complete, or
                        more timely sources of information. Any reliance on the
                        material on this site is at your own risk. This site may
                        contain certain historical information. Historical
                        information, necessarily, is not current and is provided
                        for your reference only. We reserve the right to modify
                        the contents of this site at any time, but we have no
                        obligation to update any information on our site. You
                        agree that it is your responsibility to monitor changes
                        to our site.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong className="section-heading">
                          <u>COMMUNITY GUIDELINES</u>
                        </strong>
                        <div className="section-space"></div>
                        Quick Registries community, like any community, functions
                        best when its users follow a few simple rules. By
                        accessing and/or using the Platform and/or the Services,
                        you agree to comply with these community guidelines (the
                        “Community Guidelines”) and that:
                        <div className="paragraph-space"></div>
                        <ul className="outer-list">
                          <li>
                            <p>
                              You will comply with all applicable laws in your
                              use of the Platform and/or the Services and will
                              not use the Platform and/or the Services for any
                              unlawful purpose;
                            </p>
                          </li>
                          <li>
                            <p>
                              You will not upload, post, e-mail, transmit, or
                              otherwise make available any content that:
                              <div className="paragraph-space"></div>
                              <ul className="inner-list">
                                <li>
                                  <p>
                                    infringes any copyright, trademark, right of
                                    publicity, or other proprietary rights of
                                    any person or entity; or
                                  </p>
                                </li>
                                <li>
                                  <p>
                                    is defamatory, libelous, indecent, obscene,
                                    pornographic, sexually explicit, invasive of
                                    another’s privacy, promotes violence, or
                                    contains hate speech (i.e., speech that
                                    attacks or demeans a group based on race or
                                    ethnic origin, religion, disability, gender,
                                    age, veteran status, and/or sexual
                                    orientation/gender identity;) or
                                  </p>
                                </li>
                                <li>
                                  <p>
                                    discloses any sensitive information about
                                    another person, including that person’s
                                    e-mail address, postal address, phone
                                    number, credit card information, or any
                                    similar information.
                                  </p>
                                </li>
                              </ul>
                            </p>
                          </li>
                          <li>
                            <p>
                              You will not “stalk,” threaten, or otherwise
                              harass another person;
                            </p>
                          </li>
                          <li>
                            <p>
                              You will not spam or use the Platform and/or the
                              Services to engage in any commercial activities;
                            </p>
                          </li>
                          <li>
                            <p>
                              You will not access or use the Platform and/or the
                              Services to collect any market research for a
                              competing business;
                            </p>
                          </li>
                          <li>
                            <p>
                              You will not impersonate any person or entity or
                              falsely state or otherwise misrepresent your
                              affiliation with a person or entity;
                            </p>
                          </li>
                          <li>
                            <p>
                              You will not interfere with or attempt to
                              interrupt the proper operation of the Platform
                              and/or the Services through the use of any virus,
                              device, information collection or transmission
                              mechanism, software or routine, or access or
                              attempt to gain access to any data, files, or
                              passwords related to the Platform and/or the
                              Services through hacking, password or data mining,
                              or any other means;
                            </p>
                          </li>
                          <li>
                            <p>
                              You will not cover, obscure, block, or in any way
                              interfere with any advertisements and/or safety
                              features (e.g., report abuse button) on the
                              Platform and/or the Services;
                            </p>
                          </li>
                          <li>
                            <p>
                              You will not use any robot, spider, scraper, or
                              other automated means to access the Platform
                              and/or the Services for any purpose without our
                              express written permission; provided, however, we
                              grant the operators of public search engines
                              permission to use spiders to copy materials from
                              the public portions of the Platform for the sole
                              purpose of and solely to the extent necessary for
                              creating publicly-available searchable indices of
                              the materials, but not caches or archives of such
                              materials;
                            </p>
                          </li>
                          <li>
                            <p>
                              You will not take any action that imposes or may
                              impose (in our sole discretion) an unreasonable or
                              disproportionately large load on our technical
                              infrastructure; and
                            </p>
                          </li>
                          <li>
                            <p>
                              You will let us know about inappropriate content
                              of which you become aware. If you find something
                              that violates our Community Guidelines, please let
                              us know, and we’ll review it.
                            </p>
                          </li>
                        </ul>
                        <div className="paragraph-space"></div>
                        We reserve the right, in our sole and absolute
                        discretion, to deny you access to the Platform and/or
                        the Services, or any portion of the Platform and/or the
                        Services, without notice, and to remove any content that
                        does not adhere to these Community Guidelines.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong className="section-heading">
                          <u>MODIFICATIONS TO THE SERVICE AND PRICES</u>
                        </strong>
                        <div className="section-space"></div>
                        We reserve the right at any time to modify or
                        discontinue the Service (or any part or content thereof)
                        without notice at any time. We shall not be liable to
                        you or to any third-party for any modification, price
                        change, suspension, or discontinuance of the Service.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong className="section-heading">
                          <u>RESTRICTIONS</u>
                        </strong>
                        <div className="section-space"></div>
                        The Platform and the Services are only available for
                        individuals aged 13 years or older. If you are 13 or
                        older, but under the age of majority in your
                        jurisdiction, you should review this Agreement with your
                        parent or guardian to make sure that you and your parent
                        or guardian understand it.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong className="section-heading">
                          <u>SIGN-IN NAME; PASSWORD; UNIQUE IDENTIFIERS</u>
                        </strong>
                        <div className="section-space"></div>
                        During the registration process for Members and
                        Merchants, we will ask you to create an account, which
                        includes a sign-in name (“<u>Sign-In Name</u>”), a
                        password (“<u>Password</u>”), and perhaps certain
                        additional information that will assist in
                        authenticating your identity when you log-in in the
                        future (“<u>Unique Identifiers</u>”). When creating your
                        account, you must provide true, accurate, current, and
                        complete information. You are responsible for the
                        confidentiality and use of your Sign-In Name, Password,
                        and Unique Identifiers. You will promptly inform us of
                        any need to deactivate a Password or Sign-In Name, or
                        change any Unique Identifier. We reserve the right to
                        delete or change your Password, Sign-In Name, or Unique
                        Identifier at any time and for any reason.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong className="section-heading">
                          <u>FEES AND PAYMENT</u>
                        </strong>
                        <div className="section-space"></div>
                        We do not charge Members any fees for using the
                        Services.
                        <div className="paragraph-space"></div>
                        Except for the Cash Gift Fund Handling Fees (as defined
                        below) and handling fees in association with at
                        charitable donation through our partner Generous, we do
                        not charge Guests any fees for using the Services. We
                        charge handling fees for each transaction initiated by a
                        Guest to send a cash gift to a Member’s cash gift fund
                        (the “<u>Cash Gift Fund Handling Fees</u>”). All Cash
                        Gift Fund Handling Fees are non-refundable and shall be
                        made known to the Guests during the transaction process.
                        Guests shall pay Quick Registries all applicable Cash Gift
                        Handling Fees. We may use a third party payment
                        processor (“<u>Third Party Payment Processor</u>”) to
                        process payment of such fees.
                        <div className="paragraph-space"></div>
                        Guests may purchase gifts from a Member’s registry by
                        selecting the gift and completing the transaction on the
                        third party partner’s website which makes the gift
                        available for purchase (“<u>Third Party Partner</u>”).
                        Guests shall pay the Third Party Partner all fees and
                        taxes associated with such transaction.
                        <br />
                        Notwithstanding anything to the contrary set forth in
                        this Agreement, we reserve the right to begin charging
                        fees at any time upon notice to you.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong className="section-heading">
                          <u>PROHIBITED USES</u>
                        </strong>
                        <div className="section-space"></div>
                        In addition to other prohibitions as set forth in this
                        Terms and Conditions, you are prohibited from using the
                        Quick Registries site or its content: (a) for any unlawful
                        purpose; (b) to solicit others to perform or participate
                        in any unlawful acts; (c) to violate any international,
                        federal, provincial, or state regulations, rules, laws,
                        or local ordinances; (d) to infringe upon or violate our
                        intellectual property rights or the intellectual
                        property rights of others; (e) to harass, abuse, insult,
                        harm, defame, slander, disparage, intimidate, or
                        discriminate based on gender, sexual orientation,
                        religion, ethnicity, race, age, national origin, or
                        disability; (f) to submit false or misleading
                        information; (g) to upload or transmit viruses or any
                        other type of malicious code that will or may be used in
                        any way that will affect the functionality or operation
                        of the Service or of any related website, other
                        websites, or the Internet; (h) to collect or track the
                        personal information of others; (i) to spam, phish,
                        pharm, pretext, spider, crawl, or scrape; (j) for any
                        obscene or immoral purpose; or (k) to interfere with or
                        circumvent the security features of the Service or any
                        related website, other websites, or the Internet. We
                        reserve the right to terminate your use of the Service
                        or any related website for violating any of the
                        prohibited uses.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong className="section-heading">
                          <u>INTELLECTUAL PROPERTY</u>
                        </strong>
                        <div className="section-space"></div>
                        The Platform and the Services contain material, such as
                        photographs, software, text, graphics, images, sound
                        recordings, and other material provided by or on behalf
                        of Quick Registries (collectively referred to as the “
                        <u>Content</u>”). The Content may be owned by us or
                        third parties. The Content is protected under both
                        United States and foreign laws. Unauthorized use of the
                        Content may violate copyright, trademark, and other
                        laws. You have no rights in or to the Content, and you
                        will not use the Content except as permitted under this
                        Agreement. No other use is permitted without prior
                        written consent from us. You must retain all copyright
                        and other proprietary notices contained in the original
                        Content on any copy you make of the Content. You may not
                        sell, transfer, assign, license, sublicense, or modify
                        the Content or reproduce, display, publicly perform,
                        make a derivative version of, distribute, or otherwise
                        use the Content in any way for any public or commercial
                        purpose. The use or posting of the Content on any other
                        website or in a networked computer environment for any
                        purpose is expressly prohibited.
                        <div className="paragraph-space"></div>
                        If you violate any part of this Agreement, your
                        permission to access and/or use the Content, the
                        Platform, and/or the Services automatically terminates.
                        <div className="paragraph-space"></div>
                        The trademarks, service marks, and logos of
                        Quick Registries (the “<u>Quick Registries Trademarks</u>”)
                        used and displayed on the Platform are registered and
                        unregistered trademarks or service marks of
                        Quick Registries. Other company, product, and service names
                        located on the Platform may be trademarks or service
                        marks owned by others (the “
                        <u>Third-Party Trademarks</u>,” and, collectively with
                        Quick Registries Trademarks, the “<u>Trademarks</u>”).
                        Nothing on the Platform should be construed as granting,
                        by implication, estoppel, or otherwise, any license or
                        right to use the Trademarks, without our prior written
                        permission specific for each such use. Use of the
                        Trademarks as part of a link to or from any site is
                        prohibited unless establishment of such a link is
                        approved in advance by us in writing. All goodwill
                        generated from the use of Quick Registries Trademarks
                        inures to our benefit.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong className="section-heading">
                          <u>MEMBER CONTENT; LICENSES</u>
                        </strong>
                        <div className="section-space"></div>
                        As noted above, the Services provide Members the ability
                        to post and upload Member Content. You expressly
                        acknowledge and agree that once you submit your Member
                        Content for inclusion into the Platform, it will be
                        accessible by others, and that there is no
                        confidentiality or privacy with respect to such Member
                        Content, including, without limitation, any personally
                        identifying information that you may make available.
                        YOU, AND NOT QUICK REGISTRIES, ARE ENTIRELY RESPONSIBLE FOR
                        ALL YOUR MEMBER CONTENT THAT YOU UPLOAD, POST, E-MAIL,
                        OR OTHERWISE TRANSMIT VIA THE SERVICES AND YOU WILL
                        INDEMNIFY US IN RESPECT OF ANY LOSS, LIABILITY, CLAIM,
                        OR DAMAGE SUFFERED OR INCURRED BY US IN CONNECTION WITH
                        YOUR MEMBER CONTENT.
                        <div className="paragraph-space"></div>
                        You retain all copyrights and other intellectual
                        property rights in and to your own Member Content. You
                        do, however, hereby grant us a non-exclusive,
                        royalty-free, sublicensable, transferable, perpetual
                        license to modify, compile, combine with other content,
                        copy, record, synchronize, transmit, translate, format,
                        distribute, publicly display, publicly perform, and
                        otherwise use or exploit your Member Content as
                        reasonably necessary to provide the Services.
                        <div className="paragraph-space"></div>
                        If you submit Member Content to us, each such submission
                        constitutes a representation and warranty to
                        Quick Registries that such Member Content is your original
                        creation (or that you otherwise have the right to
                        provide the Member Content), that you have the rights
                        necessary to grant the license to the Member Content
                        under the prior paragraph, and that it and its use by
                        Quick Registries and its content partners as permitted by
                        this Agreement does not and will not infringe or
                        misappropriate the intellectual property or moral rights
                        of any person or contain any libelous, defamatory, or
                        obscene material or content that violates our Community
                        Guidelines.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong className="section-heading">
                          <u>USER COMMENTS, FEEDBACK, AND OTHER SUBMISSIONS</u>
                        </strong>
                        <div className="section-space"></div>
                        If, at our request, you send certain specific
                        submissions (for example contest entries) or without a
                        request from us you send creative ideas, suggestions,
                        proposals, plans, or other materials, whether online, by
                        email, by postal mail, or otherwise (collectively,
                        “comments”), you agree that we may, any time, without
                        restriction, edit, copy, publish, distribute, translate
                        and otherwise use in any medium any comments that you
                        forward to us. We are and shall be under no obligation
                        (1) to maintain any comments in confidence; (2) to pay
                        compensation for any comments; or (3) to respond to any
                        comments. We may, but have no obligation to, monitor,
                        edit or remove content that we determine in our sole
                        discretion are unlawful, offensive, threatening,
                        libelous, defamatory, pornographic, obscene or otherwise
                        objectionable or violates any party’s intellectual
                        property or these Terms of Service. You agree that your
                        comments will not violate any right of any third-party,
                        including copyright, trademark, privacy, personality or
                        other personal or proprietary right. You further agree
                        that your comments will not contain libelous or
                        otherwise unlawful, abusive or obscene material, or
                        contain any computer virus or other malware that could
                        in any way affect the operation of the Service or any
                        related website. You may not use a false e‑mail address,
                        pretend to be someone other than yourself, or otherwise
                        mislead us or third-parties as to the origin of any
                        comments. You are solely responsible for any comments
                        you make and their accuracy. We take no responsibility
                        and assume no liability for any comments posted by you
                        or any third party.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong className="section-heading">
                          <u>COMMUNICATIONS WITH US</u>
                        </strong>
                        <div className="section-space"></div>
                        Although we encourage you to e-mail us, we do not want
                        you to, and you should not, e-mail us any content that
                        contains confidential information. With respect to all
                        e-mails and communications you send to us, including,
                        but not limited to, feedback, questions, comments,
                        suggestions, and the like, we shall be free to use any
                        ideas, concepts, know-how, or techniques contained in
                        your communications for any purpose whatsoever,
                        including but not limited to, the development,
                        production, and marketing of products and services that
                        incorporate such information without compensation or
                        attribution to you.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong className="section-heading">
                          <u>NO WARRANTIES; LIMITATION OF LIABILITY</u>
                        </strong>
                        <div className="section-space"></div>
                        THE PLATFORM, THE SERVICES AND ALL CONTENT, FUNCTIONS
                        AND MATERIALS MADE AVAILABLE TO YOU THROUGH THE PLATFORM
                        IS PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS
                        WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR
                        IMPLIED, INCLUDING, WITHOUT LIMITATION, ANY WARRANTIES
                        OF TITLE, NON-INFRINGEMENT, MERCHANTABILITY OR FITNESS
                        FOR A PARTICULAR PURPOSE, AND ANY WARRANTIES ARISING
                        FROM A COURSE OF DEALING, COURSE OF PERFORMANCE, OR
                        USAGE OF TRADE.
                        <div className="paragraph-space"></div>
                        NONE OF QUICK REGISTRIES, ITS AFFILIATES, SUBSIDIARIES,
                        PARTNERS, OR ITS OR THEIR OFFICERS, DIRECTORS, EMPLOYEES
                        OR AGENTS (COLLECTIVELY THE QUICK REGISTRIES PARTIES”) OWN
                        OR CONTROL THE THIRD PARTY PARTNER WEBSITE THROUGH WHICH
                        YOU PURCHASE GIFTS. NONE OF THE QUICK REGISTRIES PARTIES IS
                        A PARTY TO, ANY TRANSACTION, COMMUNICATION OR
                        INTERACTION BETWEEN YOU AND THE THIRD PARTY PARTNER
                        WEBSITE OR BETWEEN YOU AND OTHER USERS OF THE THIRD
                        PARTY PARTNER WEBSITE OR OTHER USERS OF THE SERVICES.
                        <div className="paragraph-space"></div>
                        IN CONNECTION WITH ANY WARRANTY, CONTRACT OR COMMON LAW
                        TORT CLAIMS: (I) NONE OF THE QUICK REGISTRIES PARTIES SHALL
                        BE LIABLE FOR ANY INCIDENTAL OR CONSEQUENTIAL DAMAGES,
                        LOST PROFITS, OR DAMAGES RESULTING FROM LOST DATA OR
                        BUSINESS INTERRUPTION RESULTING FROM THE USE OR
                        INABILITY TO ACCESS AND USE THE PLATFORM OR THE
                        SERVICES, EVEN IF A QUICK REGISTRIES PARTY HAS BEEN ADVISED
                        OF THE POSSIBILITY OF SUCH DAMAGES; AND (II) ANY DIRECT
                        DAMAGES THAT YOU MAY SUFFER AS A RESULT OF YOUR USE OF
                        THE PLATFORM OR THE SERVICES SHALL BE LIMITED TO THE
                        MONIES YOU HAVE PAID US IN CONNECTION WITH YOUR USE OF
                        THE SERVICES DURING THE THREE (3) MONTHS IMMEDIATELY
                        PRECEDING THE EVENTS GIVING RISE TO THE CLAIM. SOME
                        JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN
                        WARRANTIES. THEREFORE, SOME OF THE ABOVE LIMITATIONS ON
                        WARRANTIES IN THIS SECTION MAY NOT APPLY TO YOU.
                        <div className="paragraph-space"></div>
                        THE PLATFORM AND THE SERVICES MAY CONTAIN TECHNICAL
                        INACCURACIES, TYPOGRAPHICAL ERRORS, OR OMISSIONS. WE ARE
                        NOT RESPONSIBLE FOR ANY SUCH TYPOGRAPHICAL, TECHNICAL,
                        PRICING, OR OTHER ERRORS LISTED ON OR OMITTED FROM THE
                        PLATFORM OR THE SERVICES. WE RESERVE THE RIGHT TO MAKE
                        CHANGES, CORRECTIONS, AND/OR IMPROVEMENTS TO THE
                        PLATFORM AND THE SERVICES AT ANY TIME WITHOUT NOTICE.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong className="section-heading">
                          <u>OPTIONAL TOOLS</u>
                        </strong>
                        <div className="section-space"></div>
                        We may provide you with access to third-party tools over
                        which we neither monitor nor have any control nor input.
                        You acknowledge and agree that we provide access to such
                        tools “as is” and “as available” without any warranties,
                        representations, or conditions of any kind and without
                        any endorsement. We shall have no liability whatsoever
                        arising from or relating to your use of optional
                        third-party tools. Any use by you of optional tools
                        offered through the site is entirely at your own risk
                        and discretion and you should ensure that you are
                        familiar with and approve of the terms on which tools
                        are provided by the relevant third-party provider(s).
                        <div className="paragraph-space"></div>
                        We may also, in the future, offer new services and/or
                        features through the website (including the release of
                        new tools and resources). Such new features and/or
                        services shall also be subject to these Terms of
                        Service.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong className="section-heading">
                          <u>EXTERNAL SITES</u>
                        </strong>
                        <div className="section-space"></div>
                        The Platform may contain links to third-party websites
                        (“<u>External Sites</u>”). These links are provided
                        solely as a convenience to you and not as an endorsement
                        by us of the content on such External Sites. The content
                        of such External Sites is developed and provided by
                        others. You should contact the site administrator or
                        webmaster for those External Sites if you have any
                        concerns regarding such links or any content located on
                        such External Sites. We are not responsible for the
                        content of any linked External Sites and do not make any
                        representations regarding the content or accuracy of
                        materials on such External Sites. You should take
                        precautions when downloading files from all websites to
                        protect your computer from viruses and other destructive
                        programs. If you decide to access linked External Sites,
                        you do so at your own risk.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong className="section-heading">
                          <u>SEVERABILITY</u>
                        </strong>
                        <div className="section-space"></div>
                        In the event that any provision of these Terms of
                        Service is determined to be unlawful, void, or
                        unenforceable, such provision shall nonetheless be
                        enforceable to the fullest extent permitted by
                        applicable law, and the unenforceable portion shall be
                        deemed to be severed from these Terms of Service. Such
                        determination shall not affect the validity and
                        enforceability of any other remaining provisions.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong className="section-heading">
                          <u>INDEMNIFICATION</u>
                        </strong>
                        <div className="section-space"></div>
                        You agree to defend, indemnify, and hold the
                        Quick Registries Parties harmless from and against any
                        damages, liabilities, losses, expenses, claims, actions,
                        and/or demands, including, without limitation,
                        reasonable legal and accounting fees, arising or
                        resulting from: (i) your breach of this Agreement; (ii)
                        your misuse of the Platform, Content and/or the
                        Services; (iii) your violation of any third-party
                        rights, including without limitation any copyright,
                        trademark, property, publicity, or privacy right; (iv)
                        your Member Content; and/or (v) any claims brought
                        against any Quick Registries Party by a Third Party Partner
                        as a result of your acts or omissions. We shall provide
                        notice to you of any such claim, suit, or proceeding and
                        shall assist you, at your expense, in defending any such
                        claim, suit, or proceeding. We reserve the right to
                        assume the exclusive defense and control (at your
                        expense) of any matter that is subject to
                        indemnification under this section. In such case, you
                        agree to cooperate with any reasonable requests
                        assisting our defense of such matter.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong className="section-heading">
                          <u>COMPLIANCE WITH APPLICABLE LAWS</u>
                        </strong>
                        <div className="section-space"></div>
                        The Platform and the Services are based in the United
                        States. We make no claims concerning whether the
                        Platform, the Services, and the Content may be viewed,
                        or be appropriate for use outside of the United States.
                        If you access the Platform, the Services, or the Content
                        from outside of the United States, you do so at your own
                        risk. Whether inside or outside of the United States,
                        you are solely responsible for ensuring compliance with
                        the laws of your specific jurisdiction.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong className="section-heading">
                          <u>TERMINATION OF THE AGREEMENT</u>
                        </strong>
                        <div className="section-space"></div>
                        We reserve the right, in our sole discretion, to
                        restrict, suspend, or terminate this Agreement and your
                        access to all or any part of the Platform and/or the
                        Services, at any time and for any reason without prior
                        notice or liability. You may terminate your account at
                        any time by following the instructions in your account
                        settings. We reserve the right to change, suspend, or
                        discontinue all or any part of the Platform and/or the
                        Services at any time without prior notice or liability.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong className="section-heading">
                          <u>DIGITAL MILLENNIUM COPYRIGHT ACT</u>
                        </strong>
                        <div className="section-space"></div>
                        Quick Registries respects the intellectual property rights
                        of others and attempts to comply with all relevant laws.
                        We will review all claims of copyright infringement
                        received and remove any Content deemed to have been
                        posted or distributed in violation of any such laws.
                        <div className="paragraph-space"></div>
                        Our designated agent under the Digital Millennium
                        Copyright Act (the “<u>Act</u>”) for the receipt of any
                        Notification of Claimed Infringement which may be given
                        under that Act is as follows:
                        <div className="paragraph-space"></div>
                        Quick Registries
                        <br></br>
                        <Link
                          to="mailto:customercare@quickregistries.com"
                          className="custom-link"
                        >
                          Copyright@quickregistries.com
                        </Link>
                        <div className="paragraph-space"></div>
                        If you believe that your work has been copied on the
                        Platform in a way that constitutes copyright
                        infringement, please provide our agent with notice in
                        accordance with the requirements of the Act, including
                        (i) a description of the copyrighted work that has been
                        infringed and the specific location on the Platform
                        where such work is located; (ii) a description of the
                        location of the original or an authorized copy of the
                        copyrighted work; (iii) your address, telephone number
                        and e-mail address; (iv) a statement by you that you
                        have a good faith belief that the disputed use is not
                        authorized by the copyright owner, its agent or the law;
                        (v) a statement by you, made under penalty of perjury,
                        that the information in your notice is accurate and that
                        you are the copyright owner or authorized to act on the
                        copyright owner’s behalf; and (vi) an electronic or
                        physical signature of the owner of the copyright or the
                        person authorized to act on behalf of the owner of the
                        copyright interest.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong className="section-heading">
                          <u>CONTROLLING LAW</u>
                        </strong>
                        <div className="section-space"></div>
                        This Agreement and any action related thereto will be
                        governed by the laws of the State of New Jersey without
                        regard to its conflict of laws provisions.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong className="section-heading">
                          <u>BINDING ARBITRATION</u>
                        </strong>
                        <div className="section-space"></div>
                        In the event of a dispute arising under or relating to
                        this Agreement (each, a “<u>Dispute</u>”), either party
                        may elect to finally and exclusively resolve the dispute
                        by binding arbitration governed by the Federal
                        Arbitration Act (“<u>FAA</u>”). Any election to
                        arbitrate, at any time, shall be final and binding on
                        the other party. IF EITHER PARTY CHOOSES ARBITRATION,
                        NEITHER PARTY SHALL HAVE THE RIGHT TO LITIGATE SUCH
                        CLAIM IN COURT OR TO HAVE A JURY TRIAL, EXCEPT EITHER
                        PARTY MAY BRING ITS CLAIM IN ITS LOCAL SMALL CLAIMS
                        COURT, IF PERMITTED BY THAT SMALL CLAIMS COURT RULES AND
                        IF WITHIN SUCH COURT’S JURISDICTION. ARBITRATION IS
                        DIFFERENT FROM COURT, AND DISCOVERY AND APPEAL RIGHTS
                        MAY ALSO BE LIMITED IN ARBITRATION. All disputes will be
                        resolved before a neutral arbitrator, whose decision
                        will be final except for a limited right of appeal under
                        the FAA. The arbitration shall be commenced and
                        conducted by the Judicial Arbitration and Mediation
                        Services (“<u>JAMS</u>”) pursuant to its then current
                        Comprehensive Arbitration Rules and Procedures and in
                        accordance with the Expedited Procedures in those rules,
                        or, where appropriate, pursuant to JAMS’ Streamlined
                        Arbitration Rules and Procedures. All applicable JAMS’
                        rules and procedures are available at the JAMS website{" "}
                        <Link
                          to="http://www.jamsadr.com"
                          className="custom-link"
                        >
                          http://www.jamsadr.com
                        </Link>
                        . Each party will be responsible for paying any JAMS
                        filing, administrative and arbitrator fees in accordance
                        with JAMS rules. Judgment on the arbitrator’s award may
                        be entered in any court having jurisdiction. This clause
                        shall not preclude parties from seeking provisional
                        remedies in aid of arbitration from a court of
                        appropriate jurisdiction. The arbitration may be
                        conducted in person, through the submission of
                        documents, by phone, or online. If conducted in person,
                        the arbitration shall take place in the United States
                        county where you reside. The parties may litigate in
                        court to compel arbitration, to stay a proceeding
                        pending arbitration, or to confirm, modify, vacate or
                        enter judgment on the award entered by the arbitrator.
                        The parties shall cooperate in good faith in the
                        voluntary and informal exchange of all non-privileged
                        documents and other information (including
                        electronically stored information) relevant to the
                        Dispute immediately after commencement of the
                        arbitration. Nothing in this Agreement will prevent us
                        from seeking injunctive relief in any court of competent
                        jurisdiction as necessary to protect our proprietary
                        interests.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong className="section-heading">
                          <u>CLASS ACTION WAIVER</u>
                        </strong>
                        <div className="section-space"></div>
                        You agree that any arbitration or proceeding shall be
                        limited to the Dispute between us and you individually.
                        To the full extent permitted by law, (i) no arbitration
                        or proceeding shall be joined with any other; (ii) there
                        is no right or authority for any Dispute to be
                        arbitrated or resolved on a class action-basis or to
                        utilize class action procedures; and (iii) there is no
                        right or authority for any Dispute to be brought in a
                        purported representative capacity on behalf of the
                        general public or any other persons. YOU AGREE THAT YOU
                        MAY BRING CLAIMS AGAINST US ONLY IN YOUR INDIVIDUAL
                        CAPACITY AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY
                        PURPORTED CLASS OR REPRESENTATIVE PROCEEDING.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong className="section-heading">
                          <u>MISCELLANEOUS</u>
                        </strong>
                        <div className="section-space"></div>
                        If this Agreement is terminated in accordance with the
                        Termination provision above, such termination shall not
                        affect the validity of the following provisions of this
                        Agreement, which shall remain in full force and effect:
                        “Fees and Payment” (until you pay all fees and taxes due
                        hereunder), “Intellectual Property,” “Member Content;
                        Licenses,” “Communications with Us,” “No Warranties;
                        Limitation of Liability,” “Indemnification,”
                        “Termination of the Agreement,” “Controlling Law,”
                        “Binding Arbitration,” “Class Action Waiver,” and
                        “Miscellaneous.”
                        <div className="paragraph-space"></div>
                        Our failure to act on or enforce any provision of the
                        Agreement shall not be construed as a waiver of that
                        provision or any other provision in this Agreement. No
                        waiver shall be effective against us unless made in
                        writing, and no such waiver shall be construed as a
                        waiver in any other or subsequent instance. Except as
                        expressly agreed by us and you in writing, this
                        Agreement constitutes the entire Agreement between you
                        and us with respect to the subject matter, and
                        supersedes all previous or contemporaneous agreements,
                        whether written or oral, between the parties with
                        respect to the subject matter. The section headings are
                        provided merely for convenience and shall not be given
                        any legal import. This Agreement will inure to the
                        benefit of our successors, assigns, licensees, and
                        sublicensees.
                        <div className="paragraph-space"></div>
                        Copyright {displayYear} Quick Registries. All rights reserved.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong className="section-heading">
                          <u>CHANGES TO TERMS AND CONDITIONS</u>
                        </strong>
                        <div className="section-space"></div>
                        You can review the most current version of this Terms
                        and Conditions at any time at this page. We reserve the
                        right, at our sole discretion, to update, change, or
                        replace any part of these Terms and Conditions by
                        posting updates and changes to our website. It is your
                        responsibility to check our website periodically for
                        changes. Your continued use of or access to our website
                        or the Service following the posting of any changes to
                        these Terms and Conditions constitutes acceptance of
                        those changes.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong className="section-heading">
                          <u>CONTACT INFORMATION</u>
                        </strong>
                        <div className="section-space"></div>
                        Questions about the Terms of Service should be sent to
                        us at customercare@quickregistries.com
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong className="section-heading">
                          <u>RETURN POLICY</u>
                        </strong>
                        <div className="section-space"></div>
                        As Quick Registries does not directly sell products, our
                        return policy is contingent upon the policies
                        established by the store or retailer from which you made
                        your purchase. We encourage you to familiarize yourself
                        with the specific return policy of the store or retailer
                        where you obtained the product as each store or retailer
                        may have varying timeframes, conditions, and
                        requirements. In addition, carefully reviewing return
                        policy will help ensure that you comply with their
                        specific guidelines for return, exchanges, refunds, or
                        any other related inquiries.
                        <div className="paragraph-space"></div>
                        While Quick Registries does not have direct control over
                        the return process, we are committed to assisting you in
                        understanding the guidelines and procedures for
                        returning products. If you have any questions or require
                        assistance regarding the return policy of the store you
                        purchased from, please feel free to contact our customer
                        support team at{" "}
                        <Link
                          to="mailto:customercare@quickregistries.com"
                          className="custom-link"
                        >
                          customercare@quickregistries.com
                        </Link>
                        . We will do our best to provide guidance and help
                        facilitate communication between you and the store to
                        address any concerns you may have.
                        <div className="paragraph-space"></div>
                        Please understand that while we are here to support you
                        throughout the process, any decisions regarding returns,
                        exchanges, or refunds ultimately rest with the store or
                        retailer where the purchase was made. We appreciate your
                        understanding, if you have any further questions or need
                        additional questions or need additional assistance,
                        please do not hesitate to contact us.
                      </p>
                    </li>
                  </ol>
                  <div className="mb-5">
                    <p>Copyright {displayYear} Quick Registries. All rights reserved.</p>
                    <p>
                      This Terms and Conditions Statement is effective May 06,
                      2024.
                      <br />
                      <b>Last Updated</b>: 05/06/2024
                    </p>
                  </div>
                </div>
              </div>
            </section>
          </article>
        </main>
        <Footer />
      </div>
    </div>
  );
};

export default TermsAndConditionPage;
