import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Header from "../../components/common/Header";
import Navbar from "../../components/common/Navbar";
import Footer from "../../components/common/Footer";

const ScriptBlockedPage = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const targetUrl = searchParams.get('url');
  
  const domain = targetUrl ? new URL(targetUrl).hostname : "This website";

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="wrapper animsition">
      <div className="wrapper-inner">
        <header className="header">
          <Header />
          <Navbar />
        </header>
        <main className="main">
          <article>
            <section className="py-5">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="try-extension-container">
                      <div className="text-center">
                        <p className="block-website my-5">
                          {domain} is blocking the QuickRegistries.com Bookmark.
                        </p>
                        <h3 className="use-extension mb-3">Try our extension instead!</h3>
                      </div>
                      <div className="last-steps-info-container">
                        <div className="how-it-works-container mb-4">
                          <p className="last-step-heading">How it works</p>
                        </div>
                        <span className="mulitple-list-number">1</span>
                        <span className="last-steps-details-container">
                          <span className="last-step-heading">
                            <Link
                              to="https://chromewebstore.google.com/detail/quick-registry/bpfmfjcokemkfaofkheknefimcijonke?hl=en&authuser=8"
                              target="_blank"
                              rel="noopener noreferrer"
                              className="change-new-color"
                            >
                              Click here
                            </Link> to add and
                            <Link
                              to="https://support.google.com/chrome/a/answer/11190170?hl=en"
                              target="_blank"
                              className="change-new-color"
                            > pin it
                            </Link> to your browser
                          </span>
                          <br />
                          <span>
                            Just like your other extensions, it takes just a few clicks.
                          </span>
                        </span>
                      </div>
                      <div className="last-steps-info-container add-qr-container">
                        <div className="onboarding-btn add-chrome-btn">
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() => window.open("https://chromewebstore.google.com/detail/quick-registry/bpfmfjcokemkfaofkheknefimcijonke?hl=en&authuser=8", '_blank')}
                          >
                            Add to Chrome
                          </button>
                        </div>
                      </div>
                      <div className="last-steps-info-container">
                        <span className="mulitple-list-number">2</span>
                        <span className="last-steps-details-container">
                          <span className="last-step-heading">Find a gift you would like</span>
                          <br />
                          <span>
                            Visit any retail website and go to the product page of the gift you’d like to add.
                          </span>
                        </span>
                      </div>
                      <div className="last-steps-info-container">
                        <span className="mulitple-list-number">3</span>
                        <span className="last-steps-details-container">
                          <span className="last-step-heading">Add in Seconds</span>
                          <br />
                          <span>
                            Click on the button in your browser, confirm some details, add the gift.
                          </span>
                        </span>
                      </div>
                      <div className="text-center my-5">
                        <Link
                          className="bottom-nav change-color"
                          to={targetUrl}
                        >
                          Go back to {domain}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </article>
        </main>
        <Footer />
      </div>
    </div>
  );
};

export default ScriptBlockedPage;