/* global $ */
import React, {useState} from "react";
import { Link } from "react-router-dom";
import { showToast } from '../../../utils/toastUtils';
import { getRegistryCredentials } from '../../../utils/registryUtils';
import api from "../../../services/api";

const ShareRegistryModal = ({ registryPublicKey, fetchRegistryDetails }) => {
  const baseUrl = `${process.env.REACT_APP_BASE_URL}/giftlist/`;
  const { accessToken, registryId } = getRegistryCredentials();
  const [registryPublicUrl, setRegistryPublicUrl] = useState('');

  const shareOnFacebook = () => {
    const url = 'https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(baseUrl + registryPublicKey);
    window.open(url, '_blank');
  };

  const shareOnX = () => {
    const url = 'https://x.com/intent/tweet?url=' + encodeURIComponent(baseUrl + registryPublicKey);
    window.open(url, '_blank');
  };

  const shareOnWhatsApp = () => {
    const url = 'https://api.whatsapp.com/send?text=' + encodeURIComponent(baseUrl + registryPublicKey);
    window.open(url, '_blank');
  };

  const shareOnTelegram = () => {
    const url = 'https://telegram.me/share/url?url=' + encodeURIComponent(baseUrl + registryPublicKey);
    window.open(url, '_blank');
  };

  const handleCurrentRegistryUrl = () => {
    const url = baseUrl + registryPublicKey;
    window.open(url, '_blank');
  };

  const handleEditUrl = () => {
    $("#shareRegistry").modal("hide");
    $("#editRegistryUrl").modal("show");
  };

  const handleUpdateRegistryUrl = async (e) => {
    e.preventDefault();
    try {
      const response = await api.updatePublicUrl(accessToken, registryId, registryPublicUrl);
      if (response.data === "URL changed successfully") {
        showToast('Registry URL updated successfully', 'success');
        setRegistryPublicUrl('');
        fetchRegistryDetails();
        $("#editRegistryUrl").modal("hide");
        $("#shareRegistry").modal("show");
      } else if (response.error === "The public key has already been taken.") {
        showToast('URL TAKEN. PLEASE TRY ANOTHER');
      } else if (response.error === "The public key field format is invalid.") {
        showToast('INVALID URL. PLEASE TRY ANOTHER');
      } else {
        showToast('Failed to update registry URL', 'error');
      }
    } catch (error) {
      console.error("Error updating registry URL:", error);
      showToast('Failed to update registry URL', 'error');
    }
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(baseUrl + registryPublicKey);
    showToast('URL Copied');
  };

  return (
    <>
      <div className="modal fade" id="shareRegistry" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog common-popup share-registry-popup">
          <div className="modal-content">
            <div className="modal-header">
              <p>SHARE REGISTRY</p>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body mt-4">
              <div className="row">
                <div className="col-md-6">
                  <h6>ANNOUNCE YOUR REGISTRY</h6>
                  <hr className="separate-line mt-1" />
                  <p className="share-info"><Link>Send eCards</Link></p>
                  <p className="share-info"><Link>Embedded Registry on your personal site</Link></p>
                  <p className="share-info"><Link>Printable Announcements</Link></p>
                </div>

                <div className="col-md-6 mt-md-0 mt-3">
                  <h6>SHARE ON SOCIAL MEDIA</h6>
                  <hr className="separate-line mt-1" />
                  <div className="social-icons mb-0">
                    <div className="social-icon share-info mb-3">
                      <i className="la la-facebook circle-icon-wrapper" onClick={shareOnFacebook}></i>
                      <Link onClick={shareOnFacebook}> &nbsp; Facebook</Link>
                    </div>
                    <div className="social-icon share-info mb-3">
                      <i className="la la-twitter circle-icon-wrapper" onClick={shareOnX}></i>
                      <Link onClick={shareOnX}> &nbsp; Twitter</Link>
                    </div>
                    <div className="social-icon share-info mb-3">
                      <i className="la la-telegram circle-icon-wrapper" onClick={shareOnTelegram}></i>
                      <Link onClick={shareOnTelegram}> &nbsp; Telegram</Link>
                    </div>
                    <div className="social-icon share-info">
                      <i className="la la-whatsapp circle-icon-wrapper" onClick={shareOnWhatsApp}></i>
                      <Link onClick={shareOnWhatsApp}> &nbsp; WhatsApp</Link>
                    </div>
                  </div>
                </div>
              </div>

              <hr />

              <div className="row">
                <div className="col-md-10">
                  <h6 className="mb-2">Quick Registries URL:</h6>
                  <p>
                    {baseUrl + registryPublicKey}
                  </p>
                </div>
                {/* <div className="menu-icon col-md-2 my-4"> 
                  <Link to="#" onClick={handleToggleMenu}><i className="la la-ellipsis-h"></i></Link>
                </div> */}
                <div className="col-md-2 my-4">
                  <Link to="#" className="change-color" onClick={handleEditUrl}><i className="la la-edit fs-4 me-3"></i></Link>
                  <Link to="#" className="change-color" onClick={copyToClipboard}><i className="la la-clone fs-4"></i></Link>
                </div>
                {/* <ul className="dropdown-menu">
                  <li>
                    <Link to="#" className="dropdown-item">Edit</Link>
                  </li>
                  <li onClick={copyToClipboard}>
                    <Link to="#" className="dropdown-item">Copy URL</Link>
                  </li>
                </ul> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="editRegistryUrl" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog common-popup buy-mulitple-popup">
            <div className="modal-content">
              <div className="modal-header">
                <p>EDIT REGISTRY URL</p>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <div className="text-center">
                <p className="fs-5 my-3"><b>Your Registry URL:</b></p>
                <Link to="#" className="change-color" onClick={handleCurrentRegistryUrl}>{baseUrl + registryPublicKey}</Link>
                </div>

                <div className="text-center mt-5">
                  <p className="fs-6 mb-3"><b>CHANGE URL (OPTIONAL):</b></p>
                  <div className="registry-url-container">
                    <span className="mb-3 fs-6">{baseUrl}</span>
                    <form noValidate className="w-100">
                      <div className="user-box category-input share-input url-box">
                        <input
                          type="text"
                          name="registryUrl"
                          value={registryPublicUrl}
                          onKeyPress={(e) => e.key === 'Enter' && handleUpdateRegistryUrl(e)}
                          onChange={(e) => {
                            setRegistryPublicUrl(e.target.value);
                          }}
                          required
                        />
                        <span className="registry-url-info">*ALPHANUMERIC CHARACTERS AND DASHES ONLY. NO SPACES.</span>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              
              <div className="user-detials-btn text-end mb-4 me-3">
                {registryPublicUrl.trim() && (
                  <button
                    type="button"
                    className="btn common-btn active-btn"
                    onClick={handleUpdateRegistryUrl}
                  >
                    APPLY
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
    </>

    

    
  );
};

export default ShareRegistryModal;