import React, { useState } from "react";
import Header from "../../components/common/Header";
import Navbar from "../../components/common/Navbar";
import Footer from "../../components/common/Footer";
import { showToast } from "../../utils/toastUtils";
import api from "../../services/api";

const AdvertiseWithUsPage = () => {
    const [formData, setFormData] = useState({
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      companyName: "",
      websiteUrl: "",
      ecommercePlatform: "",
      affiliateNetwork: "",
      message: "",
    });
  
    const [validation, setValidation] = useState({
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      companyName: "",
      websiteUrl: "",
      ecommercePlatform: "",
      affiliateNetwork: "",
    });

    const handleInputChange = (e) => {
      const { name, value } = e.target;
        let newValue = value;
      
        if (name === "phoneNumber") {
          newValue = value.replace(/\D/g, "");
        }
      
        setFormData({
          ...formData,
          [name]: newValue,
        });
    
        setValidation((prevValidation) => ({
          ...prevValidation,
          [name]: "",
      }));
    };
  
    const handleFormSubmit = async (e) => {
        e.preventDefault();
    
        let isValid = true;
        const newValidation = { ...validation };
    
        if (!formData.firstName) {
          newValidation.firstName = "Please enter your first name";
          isValid = false;
        }

        if (!formData.lastName) {
          newValidation.lastName = "Please enter your last name";
          isValid = false;
        }
        
        if (!formData.email) {
          newValidation.email = "Please enter your email address";
          isValid = false;
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
          newValidation.email = "Please enter a valid email address";
          isValid = false;
        } else {
          newValidation.email = "";
        }
        
        if (!formData.phoneNumber) {
          newValidation.phoneNumber = "Please enter your phone number";
          isValid = false;
        } else if (!/^\d{10}$/.test(formData.phoneNumber)) {
          newValidation.phoneNumber = "Please enter a valid 10-digit phone number";
          isValid = false;
        } else {
          newValidation.phoneNumber = "";
        }

        if (!formData.companyName) {
          newValidation.companyName = "Please enter company name";
          isValid = false;
        }
        
        if (!formData.message) {
          newValidation.message = "Please tell us your request";
          isValid = false;
        }
    
        setValidation(newValidation);
    
        if (isValid) {
          const requestData = {
            first_name: formData.firstName,
            last_name: formData.lastName,
            email: formData.email,
            mobile_no: formData.phoneNumber,
            company_name: formData.companyName,
            website_url: formData.websiteUrl || null,
            ecommerce_platform: formData.ecommercePlatform,
            affiliate_network: formData.affiliateNetwork,
            message: formData.message,
          };
      
          try {
            await api.advertiseRequest(requestData);
            showToast("Your request has been submitted successfully!", "success");
    
            setFormData({
              firstName: "",
              lastName: "",
              email: "",
              phoneNumber: "",
              companyName: "",
              websiteUrl: "",
              ecommercePlatform: "",
              affiliateNetwork: "",
              message: "",
            });
          } catch (error) {
            console.error("Error submitting advertise request:", error);
            showToast("Failed to submit request", "error");
          }
        }
      };

  return (
    <div className="wrapper animsition">
      <div className="wrapper-inner">
        <header className="header">
          <Header />
          <Navbar />
        </header>
        <main className="main">
          <article>
            <section className="py-5">
              <div className="container form-heading text-justify">
                <p className="aboutus-heading text-center">Advertise with us</p>
                <p className="mt-3">
                  If you are interested in listing your business or service with
                  QuickRegistries.com, please request a listing by completing the
                  form below. One of our sales representatives will contact you
                  shortly with more information.
                </p>
              </div>
              <div className="col-md-12">
                <form className="common-form" noValidate>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="user-box">
                        <input
                          type="text"
                          name="firstName"
                          value={formData.firstName}
                          onChange={handleInputChange}
                          onKeyPress={(e) =>
                            e.key === "Enter" && handleFormSubmit(e)
                          }
                          className={`form-control ${validation.firstName ? "is-invalid" : ""}`}
                          required
                        />
                        <label>First Name</label>
                        <p className="validation invalid-feedback"> {validation.firstName} </p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="user-box">
                        <input
                          type="text"
                          name="lastName"
                          value={formData.lastName}
                          onChange={handleInputChange}
                          onKeyPress={(e) =>
                            e.key === "Enter" && handleFormSubmit(e)
                          }
                          className={`form-control ${validation.lastName ? "is-invalid" : ""}`}
                          required
                        />
                        <label>Last Name</label>
                        <p className="validation invalid-feedback"> {validation.lastName} </p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="user-box">
                        <input
                          type="text"
                          name="email"
                          value={formData.email}
                          onChange={handleInputChange}
                          onKeyPress={(e) =>
                            e.key === "Enter" && handleFormSubmit(e)
                          }
                          className={`form-control ${validation.email ? "is-invalid" : ""}`}
                          required
                        />
                        <label>Email</label>
                        <p className="validation invalid-feedback"> {validation.email} </p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="user-box">
                        <input
                          type="tel"
                          name="phoneNumber"
                          value={formData.phoneNumber}
                          onChange={handleInputChange}
                          onKeyPress={(e) =>
                            e.key === "Enter" && handleFormSubmit(e)
                          }
                          className={`form-control ${validation.phoneNumber ? "is-invalid" : ""}`}
                          required
                        />
                        <label>Phone Number</label>
                        <p className="validation invalid-feedback"> {validation.phoneNumber} </p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="user-box">
                        <input
                          type="text"
                          name="companyName"
                          value={formData.companyName}
                          onChange={handleInputChange}
                          onKeyPress={(e) =>
                            e.key === "Enter" && handleFormSubmit(e)
                          }
                          className={`form-control ${validation.companyName ? "is-invalid" : ""}`}
                          required
                        />
                        <label>Company Name</label>
                        <p className="validation invalid-feedback"> {validation.companyName} </p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="user-box">
                        <input
                          type="text"
                          name="websiteUrl"
                          value={formData.websiteUrl}
                          onChange={handleInputChange}
                          onKeyPress={(e) =>
                            e.key === "Enter" && handleFormSubmit(e)
                          }
                          required
                        />
                        <label>Website URL (optional)</label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="select-wrapper static-wrapper user-box">
                      <select
                        id="eCommerce"
                        name="ecommercePlatform"
                        value={formData.ecommercePlatform}
                        onChange={handleInputChange}
                        className={`form-control ${validation.ecommercePlatform ? "is-invalid" : ""}`}
                        >
                        <option>ECommerce Platform</option>
                        {[
                            "Shopify",
                            "BigCommerce",
                            "Magento 1.0",
                            "Magento 2.0",
                            "Volusion",
                            "WordPress/WooCommerce",
                            "Lightspeed",
                            "3DCart",
                            "Squarespace",
                            "Wix",
                            "Weebly",
                            "Websphere Commerce",
                            "Demandware/ Salesforce Commerce Cloud",
                            "Netsuite",
                            "Custom",
                            "Woo commerce",
                            "Other",
                            "N/A"
                        ].map((option, index) => (
                            <option key={index} value={option}>{option}</option>
                        ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="select-wrapper static-wrapper user-box">
                      <select
                        id="affiliate"
                        name="affiliateNetwork"
                        value={formData.affiliateNetwork}
                        onChange={handleInputChange}
                        className={`form-control ${validation.affiliateNetwork ? "is-invalid" : ""}`}
                        >
                        <option>Affiliate Network</option>
                        {[
                            "RAN",
                            "CJ",
                            "Pepperjam/Ascend",
                            "Partnerize",
                            "SAS",
                            "Impact",
                            "Awin",
                            "Other",
                            "No Affiliate Program",
                            "Connect through Skimlinks",
                            "RAN - Connect via Skimlinks",
                            "CJ - Connect via Skimlinks",
                            "Impact - Connect via Skimlinks",
                            "SAS - Connect via Skimlinks",
                            "Awin - Connect via Skimlinks",
                            "Pepperjam/Ascend - Connect via Skimlinks",
                            "Partnerize - Connect via Skimlinks",
                            "Webgains"
                        ].map((option, index) => (
                            <option key={index} value={option}>{option}</option>
                        ))}
                      </select>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <label className="mt-4 mb-2">Message</label>
                      <div className="user-box">
                        <textarea
                          id="message"
                          name="message"
                          value={formData.message}
                          onChange={handleInputChange}
                          placeholder="Please tell us your request"
                          className={`form-control ${validation.message ? "is-invalid" : ""}`}
                        />
                        <p className="validation invalid-feedback m-2"> {validation.message} </p>
                      </div>
                    </div>
                    <div className="mt-4 mb-5">
                      <button
                        className="btn btn-primary form-btn static-btn"
                        onClick={handleFormSubmit}
                       >
                       Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </section>
          </article>
        </main>
        <Footer />
      </div>
    </div>
  );
};

export default AdvertiseWithUsPage;
