/* global $ */
import React, { useState, useEffect, useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import api from "../../../services/api";
import { showToast } from '../../../utils/toastUtils';
import { getRegistryCredentials } from '../../../utils/registryUtils';
import Cookies from 'js-cookie';

const GuestbookModal = () => {
  const recaptchaRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [guestFirstName, setGuestFirstName] = useState("");
  const [guestLastName, setGuestLastName] = useState("");
  const [guestEmail, setGuestEmail] = useState("");
  const [guestMessage, setGuestMessage] = useState("");
  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const [remainingChars, setRemainingChars] = useState(500);
  const [isFormValid, setIsFormValid] = useState(false);
  const { registryId, guestbookSigned, fromMobileApp } = getRegistryCredentials(); 

  useEffect(() => {
    const validateForm = () => {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      
      const isValid =
        guestFirstName.trim() !== "" &&
        guestLastName.trim() !== "" &&
        guestEmail.trim() !== "" &&
        emailPattern.test(guestEmail) &&
        guestMessage.trim() !== "" &&
        recaptchaValue !== null;
  
      setIsFormValid(isValid);
    };
  
    validateForm();
  }, [guestFirstName, guestLastName, guestEmail, guestMessage, recaptchaValue]);
  
  const handleFirstNameChange = (e) => {
    setGuestFirstName(e.target.value);
  };

  const handleLastNameChange = (e) => {
    setGuestLastName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setGuestEmail(e.target.value);
  };

  const handleMessageChange = (e) => {
    const value = e.target.value;
    setGuestMessage(value);
    setRemainingChars(500 - value.length);
  };

  const handleRecaptchaChange = (value) => {
    setRecaptchaValue(value);
  };

  const handleSubmit = async () => {
    if (isFormValid) {
      setLoading(true);
      try {
        const guestName = `${guestFirstName} ${guestLastName}`;
        await api.guestDiaryEntry(guestName, guestMessage, guestEmail, registryId);

        Cookies.set('guestbookSigned', true, { expires: 30, sameSite: 'None', secure: true });
        localStorage.setItem('guestbookSigned', true);
        resetGuestBook();
        showToast('Guestbook signed successfully!', "success");
        $("#guest-book").modal("hide");
      } catch (error) {
        console.error("Error signing guestbook:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleGuestBookCancel = () => {
    if(!guestbookSigned){
      resetGuestBook();
    }
  };

  const resetGuestBook = () => {
    setGuestFirstName("");
    setGuestLastName("");
    setGuestEmail("");
    setGuestMessage("");
    setRemainingChars(500);
    setIsFormValid(false);
    recaptchaRef.current.reset();
  };

  return (
    <div className="modal fade" id="guest-book" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog common-popup purchaser-details-popup">
        <div className="modal-content">
          <div className="modal-header">
            {guestbookSigned ? <p>GUESTBOOK</p> : <p>SIGN OUR GUESTBOOK</p>}
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleGuestBookCancel}></button>
          </div>
          <div className="modal-body login-box row">
          {guestbookSigned ? (
            <p className="mt-5 mb-5 fs-5 text-center">You have already signed this guestbook.</p>
          ) : (
            <div className="product-form mt-3">
            <h5 className="mb-3">SIGN GUESTBOOK</h5>
              <form noValidate>
                <div className="user-box product-field">
                  <div className="product-column">
                    <input
                      type="text"
                      name="firstName"
                      value={guestFirstName}
                      onChange={handleFirstNameChange}
                      required
                    />
                    <label>First Name</label>
                  </div>
                  <div className="product-column">
                    <input
                      type="text"
                      name="lastName"
                      value={guestLastName}
                      onChange={handleLastNameChange}
                      required
                    />
                    <label>Last Name</label>
                  </div>
                </div>

                <div className="user-box">
                  <input
                    type="email"
                    name="email"
                    value={guestEmail}
                    onChange={handleEmailChange}
                    required
                  />
                  <label>Your Email</label>
                </div>

                <label className="mb-1">Your Message</label>
                <div className="user-box mb-4">
                  <textarea
                    id="message"
                    name="Message"
                    placeholder="Enter Message here..."
                    value={guestMessage}
                    onChange={handleMessageChange}
                    maxLength={500}
                  />
                  <div className="char-count">
                    {remainingChars} characters left
                  </div>
                </div>
                {!fromMobileApp && (
                  <div className="text-center">
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      sitekey="6LchMrkqAAAAAJpkjLdTpOZca9NKsjwNuKQcv2ob"
                      onChange={handleRecaptchaChange}
                      style={{ display: "inline-block" }} 
                    />
                  </div>
                )}
              </form>
              <button
                type="button"
                className={`btn form-btn edit-gift-btn guestbook-btn mx-auto my-2`}
                onClick={handleSubmit}
                disabled={!isFormValid || loading}
              >
               {loading ? "SUBMITTING..." : "SUBMIT"}
              </button>
            </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GuestbookModal;