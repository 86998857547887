// eslint-disable-next-line
import { useEffect } from "react";
import { App } from "@capacitor/app";
import { useNavigate } from "react-router-dom";
import { getRegistryCredentials } from '../../utils/registryUtils';

const AppUrlListener = () => {
  const navigate = useNavigate();
  const { accessToken, fromMobileApp, fromiOSDevice } = getRegistryCredentials();

  useEffect(() => {
    App.addListener("appUrlOpen", (event) => {
    
      if (fromMobileApp && !fromiOSDevice) {
        const domain = event.url.includes("quickregistries.com") ? "quickregistries.com" : event.url.includes("quickregistry.in") ? "quickregistry.in" : null;
        if (domain) {
          const visitorLink = event.url.split(domain)[1] || "/";

          if (visitorLink === "/") {
              navigate(accessToken ? "/dashboard" : "/m-homepage");
          } else {
              navigate(visitorLink);
          }
        } else {
            console.error("Invalid domain in URL:", event.url);
        }
      }
      if (fromiOSDevice) {
        const trimmedUrl = event.url.split("share_url=").pop();
        if (trimmedUrl) {
          const normalizedUrl = trimmedUrl.replace(/^https?:\/\/(www\.)?/, "");

          if (["quickregistries.com/","quickregistry.in/","quickregistry.in","quickregistries.com"].includes(normalizedUrl)) {
            if (accessToken) {
              navigate("/dashboard");
            } else {
              navigate("/m-homepage");
            }
          } else {
            const urlPattern = /giftlist\/[a-zA-Z0-9_-]+/;
            const match = trimmedUrl.match(urlPattern);

            if (match) {
              const extractedPath = match[0];
              navigate(`/${extractedPath}`);
            } else {
              localStorage.setItem("trimmedUrl", trimmedUrl);
              navigate("/add-gift-modal");
            }
          }
        }
      }
    });

    return () => {
      App.removeAllListeners();
    };
    // eslint-disable-next-line
  }, [navigate]);

  return null;
};

export default AppUrlListener;