/* global $ */
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { showToast } from "../../utils/toastUtils";
import { getRegistryCredentials, clearRegistryCredentials } from "../../utils/registryUtils";
import { GoogleAuth } from "@codetrix-studio/capacitor-google-auth";
import api from "../../services/api";
import Cookies from 'js-cookie';

const SelectRegistryPage = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [listLoading, setListLoading] = useState(false);
  const [registryName, setRegistryName] = useState("");
  const [registryList, setRegistryList] = useState([]);
  const [editingRegistryId, setEditingRegistryId] = useState(null);
  const [updatedRegistryName, setUpdatedRegistryName] = useState("");
  const [registryIdToDelete, setRegistryIdToDelete] = useState(null);
  const { accessToken, userId, fromiOSDevice } = getRegistryCredentials();

  const getRegistryImage = (typeId) => {
    switch (typeId) {
      case 1:
        return "assets/images/baby-registry.svg";
      case 2:
        return "assets/images/marriage-registry.svg";
      case 3:
        return "assets/images/gift.svg";
      default:
        return null;
    }
  };

  const getRegistryStatusIcon = (processComplete) => {
    if (processComplete === "false") {
      return <i className="la la-exclamation-circle text-warning status-icon" title="Registry Setup Pending" />;
    }
    return null;
  };

  useEffect(() => {
    if (!listLoading) {
      const handleLogOutClick = () => {
        document.querySelector("ul.overlay").classList.toggle("active-overlay");
      };

      const handleOutsideClick = (event) => {
        const overlay = document.querySelector("ul.overlay");
        const logOutButton = document.querySelector("button.log-out");
        if (overlay && !overlay.contains(event.target) && logOutButton && !logOutButton.contains(event.target)) {
          overlay.classList.remove("active-overlay");
        }
      };

      const logOutButton = document.querySelector("button.log-out");
      if (logOutButton) {
        logOutButton.addEventListener("click", handleLogOutClick);
      }

      document.addEventListener("click", handleOutsideClick);

      return () => {
        if (logOutButton) {
          logOutButton.removeEventListener("click", handleLogOutClick);
        }
        document.removeEventListener("click", handleOutsideClick);
      };
    }
  }, [listLoading]);

  const fetchRegistryList = async (showLoader = true) => {
    if (showLoader) {
      setListLoading(true);
    }
    try {
      const response = await api.getRegistryList(accessToken, userId);
      setUser(response.user);
      setRegistryList(response.user?.registry || []);
      if (showLoader) {
        setListLoading(false);
      }
    } catch (error) {
      console.error('Failed to fetch registry list:', error);
      showToast("Failed to fetch registry list", "error");
    }
  };
  
  useEffect(() => {
    if (accessToken) {
      fetchRegistryList(true);
    }
    // eslint-disable-next-line
  }, [accessToken, userId]);

  const handleEditName = (registryId, registryName) => {
    setEditingRegistryId(registryId);
    setUpdatedRegistryName(registryName);
  };

  const handleNameChange = (e) => {
    setUpdatedRegistryName(e.target.value);
  };

  const handleKeyDown = (e, registryId) => {
    if (e.key === "Enter") {
      handleSaveName(registryId);
    }
  };

  const handleSaveName = async (registryId) => {
    if (!updatedRegistryName.trim()) {
      showToast("Please enter a name for the registry");
      return;
    }
    
    setLoading(true);
    try {
      await api.updateRegistryDetails(accessToken, registryId, { name: updatedRegistryName });
      fetchRegistryList(false);
      setEditingRegistryId(null);
      showToast("Registry name updated successfully", "success");
    } catch (error) {
      console.error('Failed to update registry name:', error);
      showToast("Failed to update registry name", "error");
    } finally {
      setLoading(false);
    }
  };

  const handleRegistrySwitch = (registryId) => {
    const selectedRegistry = registryList.find((registry) => registry.id === registryId);
  
    if (!selectedRegistry) {
      showToast("Registry not found", "error");
      return;
    }
  
    const { registry_type_id, registry_type_name, public: registryPublic, process_complete } = selectedRegistry;
  
    const setRegistryCookiesAndStorage = (registryData) => {
      Object.entries(registryData).forEach(([key, value]) => {
        Cookies.set(key, value, { expires: 30, sameSite: 'None', secure: true });
        localStorage.setItem(key, value);
      });
    };
  
    if (process_complete === "true") {
      setRegistryCookiesAndStorage({
        registryId,
        registryTypeId: registry_type_id,
        registryTypeName: registry_type_name,
        registryPublic,
      });
      navigate('/dashboard', { replace: true });
    } else {
      setRegistryCookiesAndStorage({
        stagingRegistryId: registryId,
        stagingRegistryTypeId: registry_type_id,
      });
      navigate("/onboarding-process?processStep=2");
      showToast('Please complete your registry setup');
    }
  };  
  
  const handleCreateRegistryNav = () => {
    navigate("/onboarding-process");
  };

  const handleDeleteRegistry = (registryId, registryName) => {
    setRegistryIdToDelete(registryId);
    setRegistryName(registryName);
    $("#delete-registry-modal").modal("show");
  };

  const handleConfirmDelete = async () => {
    setIsDeleting(true);
    try {
      await api.deleteRegistryPermanently(accessToken, registryIdToDelete);
      showToast("Registry deleted successfully", "success");
      fetchRegistryList(false);
      if (registryList.length === 1) {
        handleLogout();
      }
    } catch (error) {
      console.error("Failed to delete registry:", error);
      showToast("Failed to delete registry", "error");
    } finally {
      setIsDeleting(false);
      setRegistryIdToDelete(null);
      $("#delete-registry-modal").modal("hide");
    }
  };

  const handleCancelDelete = () => {
    setRegistryIdToDelete(null);
    $("#delete-registry-modal").modal("hide");
  };

  const handleLogout = async () => {
    try {
      await api.logout(accessToken);
      GoogleAuth.signOut();
      clearRegistryCredentials();
      window.location.pathname = "/";
    } catch (error) {
      clearRegistryCredentials();
      window.location.pathname = "/";
      console.error("Logout error:", error.message);
    }
  };

  return (
    <div className="wrapper animsition">
      <div className="wrapper-inner">
        <main className="main">
          <article>
            <section className="onboarding-process">
              {listLoading ? (
                <div className="spinner-overlay">
                  <div className="spinner-grow custom-shop-spinner" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              ) : (
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="process-box text-center">
                      {fromiOSDevice && <div className="spacing-mid"></div>}
                      <div className="spacing-mid"></div>
                      <Link
                        className="navbar-logo"
                      >
                        <img
                          src="assets/images/logo.png"
                          alt="logo"
                          loading="lazy"
                        />
                      </Link>
                      <div className="spacing-mid"></div>
                      <div className="col-md-12 process-heading text-center">
                        <h2 className="mb-4">Hi, {user ? `${user.first_name} ${user.last_name}` : 'User'}!</h2>
                        <h4>Please Select a Registry to Continue</h4>
                        <div className="spacing-mid"></div>
                      </div>
                    </div>
                    <div className="select-registry-container">
                      {registryList.map((registry) => (
                        <div key={registry.id} className="registry-wrapper">
                          <div className="registry-circle">
                            <button type="button" onClick={() => handleRegistrySwitch(registry.id)}>
                              <img src={getRegistryImage(registry.registry_type_id)} alt={registry.registry_type_name} />
                            </button>
                          </div>
                          <h4>
                            {editingRegistryId === registry.id ? (
                              <input
                                type="text"
                                value={updatedRegistryName}
                                onChange={handleNameChange}
                                onKeyDown={(e) => handleKeyDown(e, registry.id)}
                                className="registry-name-input"
                                maxLength="30"
                              />
                            ) : (
                              <>
                                {registry.name}
                                {getRegistryStatusIcon(registry.process_complete)}
                              </>
                            )}
                            <div className="action-icon-container">
                              <Link
                                className={`change-color action-icon ${editingRegistryId !== null && editingRegistryId !== registry.id ? "custom-disabled" : ""}`}
                                onClick={() =>
                                  editingRegistryId === registry.id
                                    ? handleSaveName(registry.id)
                                    : handleEditName(registry.id, registry.name)
                                }
                              >
                                {loading && editingRegistryId === registry.id ? (
                                <i className="la la-spinner la-spin fs-5"></i>
                              ) : (
                                <i className={`${editingRegistryId === registry.id ? "la la-check" : "la la-pen"}`}></i>
                              )}
                              </Link>
                              {editingRegistryId !== registry.id && (
                                <Link
                                  className={`change-color action-icon ${editingRegistryId !== null && editingRegistryId !== registry.id ? "custom-disabled" : ""}`}
                                  onClick={() => handleDeleteRegistry(registry.id, registry.name)}
                                >
                                  <i className="la la-trash fs-5"></i>
                                </Link>
                              )}
                            </div>
                        </h4>
                      </div>
                      ))}
                      <div className="registry-wrapper">
                        <div className="registry-circle plus-circle">
                          <button type="button" onClick={handleCreateRegistryNav}>
                            <img src="assets/images/plus-icon.svg" alt="create-new-registry" />
                          </button>
                        </div>
                        <h4>Create new registry</h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div
                      className={`login-text ${fromiOSDevice && "ios-area"}`}
                    >
                      <p className="fs-6 mt-1">
                        <b>HI, {user ? user.first_name.toUpperCase() : 'USER'}</b>
                        <button type="button" className="log-out">
                          <i className="la la-angle-down"></i>
                        </button>
                        <ul className="overlay">
                          <li>
                            <Link to="#" onClick={handleLogout}><i className="la la-sign-out-alt fs-6 me-1"></i>Log Out</Link>
                          </li>
                        </ul>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              )}
            </section>
          </article>
        </main>

        <div className="modal fade" id="delete-registry-modal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-body text-center mt-3">
                <div className="confirmation-modal text-center mt-4">
                  <h5 className="mb-4">Delete Registry Permanently</h5>
                  <p>
                    Are you sure you want to delete <strong>{registryName}</strong>?
                    {registryList.length === 1 && (
                      <span className="text-danger note-delete d-block mt-3">
                        Deleting <strong>{registryName}</strong> will also delete your Quick Registries account.
                      </span>
                    )}
                  </p>
                </div>
                <div className="user-detials-btn adjust-lower-btn text-center mb-3">
                  <button className="common-modal-btn common-btn mb-2" onClick={handleCancelDelete}>CANCEL</button>
                  <button className="btn common-modal-btn common-btn active-btn" onClick={handleConfirmDelete} disabled={isDeleting}>CONFIRM</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectRegistryPage;