/* global $ */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { showToast } from "../../../utils/toastUtils";
import api from "../../../services/api";
import { getRegistryCredentials } from '../../../utils/registryUtils';

const EditGiftModal = ({registryItems, registryDetails, itemId, fetchRegistryDetails, fetchRegistryItems}) => {
  const [editedName, setEditedName] = useState("");
  const [editedPrice, setEditedPrice] = useState("");
  const [editedSize, setEditedSize] = useState("");
  const [editedColor, setEditedColor] = useState("");
  const [editedRequiredQty, setEditedRequiredQty] = useState("");
  const [purchasedQty, setPurchasedQty] = useState("");
  const [editedNote, setEditedNote] = useState("");
  const [isFavorite, setIsFavorite] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [useUserDetails, setUseUserDetails] = useState(false);
  const [purchaserFirstName, setPurchaserFirstName] = useState("");
  const [purchaserLastName, setPurchaserLastName] = useState("");
  const [purchaserEmail, setPurchaserEmail] = useState("");
  const [quantityPurchased, setQuantityPurchased] = useState(1);
  const [isGiftTitleValid, setIsGiftTitleValid] = useState(true);
  const [isPriceValid, setIsPriceValid] = useState(true);
  const [isQtyValid, setIsQtyValid] = useState(true);
  const [isFirstNameValid, setIsFirstNameValid] = useState(true);
  const [isLastNameValid, setIsLastNameValid] = useState(true);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isPurchasedQtyValid, setIsPurchasedQtyValid] = useState(true);
  const [selectedRegistryItem, setSelectedRegistryItem] = useState(null);
  const [isMarkAsPurchasedVisible, setIsMarkAsPurchasedVisible] = useState(false);
  const { accessToken } = getRegistryCredentials();

  useEffect(() => {
    if (itemId) {
      const selectedRegistryItem = registryItems.find(item => item.id === itemId);
      if (selectedRegistryItem) {
        setEditedName(selectedRegistryItem.item_name || "");
        setEditedPrice(selectedRegistryItem.price || "");
        setEditedSize(selectedRegistryItem.item_details.size || "");
        setEditedColor(selectedRegistryItem.item_details.color || "");
        setEditedRequiredQty(selectedRegistryItem.required_qty || "");
        setPurchasedQty(selectedRegistryItem.purchased_qty);
        setEditedNote(selectedRegistryItem.item_details.note || "");
        setIsFavorite(selectedRegistryItem.favorite || false);
        setSelectedCategory(selectedRegistryItem.item_category_id);
        setSelectedRegistryItem(selectedRegistryItem);
      }
    }
  }, [itemId, registryItems]);

  const handleGiftInputChange = (field, value) => {
    switch (field) {
      case "name":
        setEditedName(value);
        setIsGiftTitleValid(true);
        break;
      case "price":
        setEditedPrice(value.replace("₹", "").trim());
        setIsPriceValid(true);
        break;
      case "size":
        setEditedSize(value);
        break;
      case "color":
        setEditedColor(value);
        break;
      case "required_qty":
        if (value === "" || isNaN(value)) {
          setEditedRequiredQty("");
        } else {
          const parsedValue = parseInt(value, 10);
          const newValue = Math.max(1, Math.min(parsedValue, 999));
          setEditedRequiredQty(newValue.toString());
        }
        setIsQtyValid(true);
        break;
      case "note":
        setEditedNote(value);
        break;
      default:
        break;
    }
  };

  const handleToggleFavorite = () => {
    setIsFavorite(!isFavorite);
  };

  const handleEditSave = async () => {
    if (!editedName && editedPrice && editedRequiredQty) {
      showToast("Gift Title is required");
      setIsGiftTitleValid(false);
      return;
    }

    if (!editedPrice && editedName && editedRequiredQty) {
      showToast("Price is required");
      setIsPriceValid(false);
      return;
    }

    if (!editedRequiredQty && editedName && editedPrice) {
      showToast("Desired Quantity is required");
      setIsQtyValid(false);
      return;
    }

    if (!editedName || !editedPrice || !editedRequiredQty) {
      showToast("Please enter valid gifts details");
      if (!editedName) {
        setIsGiftTitleValid(false);
      }
      if (!editedPrice) {
        setIsPriceValid(false);
      }
      if (!editedRequiredQty) {
        setIsQtyValid(false);
      }
      return;
    }

    try {
      const updatedRegistryItem = {
        ...selectedRegistryItem,
        set_favorite: isFavorite,
        item_name: editedName,
        price: editedPrice,
        item_details: {
          ...selectedRegistryItem.item_details,
          size: editedSize,
          color: editedColor,
          note: editedNote
        },
        required_qty: editedRequiredQty,
        item_category_id: selectedCategory
      };
      await api.updateRegistryItems(accessToken, selectedRegistryItem.id, updatedRegistryItem);
      showToast("Gift details updated successfully", "success");

      fetchRegistryDetails();
      fetchRegistryItems();
      $("#EditGift").modal("hide");
    } catch (error) {
      console.error("Error updating gift details:", error.message);
      showToast("Failed to update gift details", "error");
    } finally {
      setSelectedRegistryItem(null);
    }
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleEditGiftCancel = () => {
    setIsMarkAsPurchasedVisible(false);
    setUseUserDetails(false);
    resetPurchaserDetails();
    setIsFirstNameValid(true);
    setIsLastNameValid(true);
    setIsEmailValid(true);
    setIsPurchasedQtyValid(true);
    setIsGiftTitleValid(true);
    setIsPriceValid(true);
    setIsQtyValid(true);
  };

  const handleNavMarkAsPurchased = () => {
    setIsMarkAsPurchasedVisible(true);
  };

  const resetPurchaserDetails = () => {
    setPurchaserFirstName("");
    setPurchaserLastName("");
    setPurchaserEmail("");
    setQuantityPurchased(1);
  };

  const handlePurchasedForMyself = () => {
    setUseUserDetails(!useUserDetails);

    if (!useUserDetails && registryDetails) {
      const { user_first_name, user_last_name, user_email } = registryDetails;
      setPurchaserFirstName(user_first_name || "");
      setPurchaserLastName(user_last_name || "");
      setPurchaserEmail(user_email || "");
    } else {
      resetPurchaserDetails();
    }
  };

  const handleFirstNameChange = (event) => {
    setPurchaserFirstName(event.target.value);
    setIsFirstNameValid(true);
  };

  const handleLastNameChange = (event) => {
    setPurchaserLastName(event.target.value);
    setIsLastNameValid(true);
  };

  const handleEmailChange = (event) => {
    setPurchaserEmail(event.target.value);
    setIsEmailValid(true);
  };

  const handleQtyChange = (event) => {
    let value = parseInt(event.target.value, 10);
    value = Math.max(1, Math.min(value, 999));
    setQuantityPurchased(value);
    setIsPurchasedQtyValid(true);
  };

  const handleMarkAsPurchased = async () => {
    try {
      if (!purchaserFirstName && purchaserLastName && purchaserEmail && quantityPurchased) {
        showToast("Please enter purchaser's first name");
        setIsFirstNameValid(false);
        return;
      }

      if (!purchaserLastName && purchaserFirstName && purchaserEmail && quantityPurchased) {
        showToast("Please enter purchaser's last name");
        setIsLastNameValid(false);
        return;
      }

      if (!purchaserEmail && purchaserFirstName && purchaserLastName && quantityPurchased) {
        showToast("Please enter valid purchaser's email");
        setIsEmailValid(false);
        return;
      }

      if (!quantityPurchased && purchaserFirstName && purchaserLastName && purchaserEmail) {
        showToast("Please enter valid gifted quantity");
        setIsPurchasedQtyValid(false);
        return;
      }

      if (!purchaserFirstName || !purchaserLastName || !purchaserEmail || !quantityPurchased) {
        showToast("Please enter valid contributor's details");
        if (!purchaserFirstName) {
          setIsFirstNameValid(false);
        }
        if (!purchaserLastName) {
          setIsLastNameValid(false);
        }
        if (!purchaserEmail) {
          setIsEmailValid(false);
        }
        if (!quantityPurchased) {
          setIsPurchasedQtyValid(false);
        }
        return;
      }

      if (!isValidEmail(purchaserEmail)) {
        showToast("Please enter a valid email address");
        setIsEmailValid(false);
        return;
      }

      await api.markItemPurchased(
        accessToken,
        `${purchaserFirstName}${
          purchaserLastName ? ` ${purchaserLastName}` : ""
        }`,
        purchaserEmail,
        selectedRegistryItem.id,
        quantityPurchased
      );

      showToast("Gift marked as gifted successfully", "success");

      fetchRegistryDetails();
      fetchRegistryItems();
      resetPurchaserDetails();
      setUseUserDetails(false);
      setIsMarkAsPurchasedVisible(false);
      $("#EditGift").modal("hide");
    } catch (error) {
      console.error("Error marking item as gifted:", error.message);
      showToast("Failed to mark gift as gifted", "error");
    }
  };

  const handleCancelMarkAsPurchased = () => {
    setIsMarkAsPurchasedVisible(false);
    setUseUserDetails(false);
    setIsFirstNameValid(true);
    setIsEmailValid(true);
    resetPurchaserDetails();
  };

  return (
    <div className="modal fade" id="EditGift" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog common-popup edit-gifts-popup">
        <div className="modal-content custom-modal-content">
          <div className="modal-header">
            <p>{isMarkAsPurchasedVisible ? "MARK THIS GIFT AS GIFTED" : "EDIT GIFT"}</p>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleEditGiftCancel}></button>
          </div>
          {selectedRegistryItem && (
            <div className="modal-body login-box row">
              <div className="col-lg-6 col-sm-6">
                <div className="product-image">
                  <div className="heart-icon">
                    <div className="heart-icon">
                      <Link to="#" onClick={handleToggleFavorite}>
                        {isFavorite ? (
                          <i className="la la-heart"></i>
                        ) : (
                          <i className="la la-heart-o"></i>
                        )}
                      </Link>
                    </div>
                  </div>
                  <div className="product-imgs">
                    <div className="img-display">
                      <div className="img-showcase">
                        {selectedRegistryItem.image ? (
                          <img
                            src={selectedRegistryItem.image}
                            alt=""
                            className="d-block mx-auto"
                            style={{ height: "365px", objectFit: "contain" }}
                          />
                        ) : (
                          <img
                            src="/assets/images/no_image_available.png"
                            alt=""
                            className="d-block mx-auto"
                            style={{ height: "365px", objectFit: "contain" }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  {isMarkAsPurchasedVisible ? null : (
                    <form className="mt-4" noValidate>
                    <label className="mb-2">Note to your guests (optional)</label>
                      <div className="user-box">
                        <textarea
                          id="note"
                          name="Note"
                          placeholder="Enter note here.."
                          value={editedNote}
                          onChange={(e) =>
                            handleGiftInputChange("note", e.target.value)
                          }
                        ></textarea>
                      </div>
                    </form>
                  )}
                </div>
              </div>
              <div className="col-lg-6 col-sm-6">
                <div className="product-form mt-3">
                  {isMarkAsPurchasedVisible ? (
                    <>
                      <div className="user-box mb-1 clamp-three-lines">
                        <h6>
                          <p>{selectedRegistryItem.item_name}</p>
                        </h6>
                      </div>
                      <div className="user-box">
                        <p className="fs-5">Enter Contributor's Details:</p>
                      </div>
                      <form noValidate>
                        <div className="user-box-checkbox mb-3">
                          <input
                            type="checkbox"
                            name="purchasedForMyself"
                            className="mt-2 mark-as-purchased"
                            checked={useUserDetails}
                            onChange={handlePurchasedForMyself}
                          />&nbsp;&nbsp;
                          <label className="purchased-text fs-6">
                            I gifted it to myself
                          </label>
                        </div>
                        
                        <div className="user-box product-field">
                          <div className="product-column">
                            <input
                              type="text"
                              name="firstName"
                              value={purchaserFirstName}
                              onChange={handleFirstNameChange}
                              className={`form-control ${
                                !isFirstNameValid ? "is-invalid" : ""
                              }`}
                              required
                            />
                            <label>First Name</label>
                          </div>
                          <div className="product-column">
                            <input
                              type="text"
                              name="lastName"
                              value={purchaserLastName}
                              onChange={handleLastNameChange}
                              className={`form-control ${
                                !isLastNameValid ? "is-invalid" : ""
                              }`}
                              autoComplete="offAuto"
                              required
                            />
                            <label>Last Name</label>
                          </div>
                        </div>
                        <div className="user-box">
                          <input
                            type="text"
                            name="email"
                            value={purchaserEmail}
                            onChange={handleEmailChange}
                            className={`form-control ${
                              !isEmailValid ? "is-invalid" : ""
                            }`}
                            autoComplete="offAuto"
                            required
                          />
                          <label>Purchaser's Email Address</label>
                        </div>

                        <div className="user-box product-field">
                          <div className="product-column">
                            <p>Received {selectedRegistryItem.purchased_qty} of {selectedRegistryItem.required_qty}</p>
                          </div>
                          <div className="product-column">
                          <input
                            type="number"
                            name="qtyPurchased"
                            value={quantityPurchased}
                            onChange={handleQtyChange}
                            className={`form-control ${
                              !isPurchasedQtyValid ? "is-invalid" : ""
                            }`}
                            autoComplete="off"
                            required
                          />
                          <label>Qty Gifted</label>
                          </div>
                        </div>
                      </form>
                      <button
                        type="button"
                        className="form-btn edit-gift-btn mb-3"
                        onClick={handleMarkAsPurchased}
                      >
                        MARK AS GIFTED
                      </button>
                      <button
                        type="button"
                        className="form-btn edit-gift-btn cancel-btn mb-2"
                        onClick={handleCancelMarkAsPurchased}
                      >
                        CANCEL
                      </button>
                    </>
                  ) : (
                    <>
                      <form noValidate>
                        <div className="user-box">
                          <input
                            type="text"
                            name="Gift Title"
                            value={editedName}
                            onChange={(e) =>
                              handleGiftInputChange("name", e.target.value)
                            }
                            className={`form-control ${
                              !isGiftTitleValid ? "is-invalid" : ""
                            }`}
                            autoComplete="off"
                            required
                          />
                          <label>Gift Title</label>
                        </div>

                        <div className="user-box product-popup-edit">
                          <div className="product-column">
                            <div className="select-wrapper">
                              <select
                                name="Currency"
                                id="currencySelect"
                                className="form-control"
                                disabled
                              >
                                <option value="Inr">INR</option>
                              </select>
                            </div>
                          </div>
                          <div className="product-column">
                            <input
                              type="text"
                              name="Price"
                              value={`₹ ${editedPrice}`}
                              onChange={(e) =>
                                handleGiftInputChange(
                                  "price",
                                  e.target.value.replace(/₹/g, "").trim()
                                )
                              }
                              className={`form-control ${
                                !isPriceValid ? "is-invalid" : ""
                              }`}
                              autoComplete="off"
                              required
                            />
                            <label>Price</label>
                          </div>
                        </div>
                        
                        <div className="user-box product-field">
                          <div className="product-column">
                            <input
                              type="text"
                              name="Size"
                              value={editedSize}
                              onChange={(e) =>
                                handleGiftInputChange("size", e.target.value)
                              }
                              autoComplete="off"
                              required
                            />
                            <label>Size</label>
                          </div>
                          <div className="product-column">
                            <input
                              type="text"
                              name="Color"
                              value={editedColor}
                              onChange={(e) =>
                                handleGiftInputChange("color", e.target.value)
                              }
                              autoComplete="off"
                              required
                            />
                            <label>Color</label>
                          </div>
                        </div>

                        <div className="user-box product-field">
                          <div className="product-column">
                            <input
                              type="number"
                              name="Desired"
                              value={editedRequiredQty}
                              onChange={(e) => {
                                handleGiftInputChange("required_qty", e.target.value);
                              }}
                              className={`form-control ${
                                !isQtyValid ? "is-invalid" : ""
                              }`}
                              autoComplete="off"
                              required
                            />
                            <label>Desired</label>
                          </div>
                          <div className="product-column">
                            <input
                              type="number"
                              name="Purchased"
                              value={purchasedQty}
                              autoComplete="off"
                              disabled
                            />
                            <label>Gifted</label>
                          </div>
                        </div>

                        <div className="mb-4">
                          <label htmlFor="Category" className="mb-2">
                            Category
                          </label>
                          <div className="select-wrapper">
                            <select
                              name="Category"
                              id="categorySelect"
                              className="form-control"
                              value={selectedCategory}
                              onChange={(e) => setSelectedCategory(parseInt(e.target.value))}
                            >
                              {registryDetails && registryDetails.item_categories && registryDetails.item_categories.map((category) => (
                                  <option key={category.id} value={category.id}>
                                      {category.name}
                                  </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </form>
                      <button
                        type="button"
                        className="form-btn edit-gift-btn mb-3"
                        onClick={handleEditSave}
                      >
                        SAVE
                      </button>
                      
                      <p className="primary-button text-center">
                        Already Received this Gift?&nbsp;
                        <button
                          type="button"
                          className={`change-color purchased-color ${selectedRegistryItem.purchased_qty >= selectedRegistryItem.required_qty ? ' custom-disabled' : ''}`}
                          onClick={handleNavMarkAsPurchased}
                        >
                          MARK AS GIFTED
                        </button>
                      </p>
                    </>
                  )}
                </div>
              </div>
              {selectedRegistryItem.status === "Unavailable" ? (
                <div className="purchaser-edit-box unavailable-edit-box mt-2 mb-3">
                  <h6 className="purchased-edit-status">UNAVAILABLE&nbsp;</h6>
                </div>
              ) : (
                selectedRegistryItem.purchased_qty > 0 && (
                  <div className="purchaser-edit-box mt-2 mb-3">
                    <h6 className="purchased-edit-status">
                      {selectedRegistryItem.purchased_qty < selectedRegistryItem.required_qty ? "PARTIALLY GIFTED" : "GIFTING COMPLETED"}
                      &nbsp;
                    </h6>
                  </div>
                )
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default EditGiftModal;
